import React from 'react';

const FootTerms = (props) => {
  return (<footer class="bg-gray-800 text-gray-300 border-t border-gray-700" style={{ backgroundColor: "#192c2b" }}>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10">
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div>
          <h2 class="text-lg font-semibold text-white">7thcentury</h2>
          <p class="mt-4 text-sm text-gray-400">
            Delivering exceptional services and solutions tailored to your needs. Join us on our journey to innovation.
          </p>
          <div class="mt-4 flex space-x-4">
            <a
              className="btn btn-primary"
              href="https://www.facebook.com/7th-Century-Music-106256021766830"
              target="self"
              role="button"
            >
              <i className="fab fa-facebook-f" />
            </a>
            <a
              className="btn btn-primary"
              href="https://www.youtube.com/channel/UCZSMJE5tMI5QTyfWEOj1d2Q"
              target="self"
              role="button"
            >
              <i className="fab fa-youtube" />
            </a>

            <a
              className="btn btn-primary"
              href="https://www.instagram.com/7th.century.music/"
              target="self"
              role="button"
            >
              <i className="fab fa-instagram" />
            </a>
            <a
              className="btn btn-primary"
              href="https://uk.linkedin.com/company/7th-century-music"
              target="self"
              role="button"
            >
              <i className="fab fa-linkedin" />
            </a>
          </div>
        </div>
        <div>
          <h3 class="text-lg font-semibold text-white">Quick Links</h3>
          <ul class="mt-4 space-y-2 text-sm">
            <li><a href="/" class="hover:text-white">About Us</a></li>
            <li><a href="/" class="hover:text-white">Contact</a></li>
            <li><a href="/news" class="hover:text-white">News</a></li>
          </ul>
        </div>

        <div>
          <h3 class="text-lg font-semibold text-white">Resources</h3>
          <ul class="mt-4 space-y-2 text-sm">
            <li><a href="/onboarding-policy" class="hover:text-white">Onboarding Policy</a></li>
            <li><a href="/questions" class="hover:text-white">FAQs</a></li>
            <li><a href="/privacypolicy" class="hover:text-white">Privacy Policy</a></li>
            <li><a href="/termsofuse" class="hover:text-white">Terms of Service</a></li>
          </ul>
        </div>
      </div>

      <div class="mt-10 border-t border-gray-700 pt-6 flex flex-col md:flex-row justify-between items-center">
        <p class="text-sm text-gray-400">© 2024 7thcentury. All rights reserved.</p>
        <ul class="flex space-x-4 mt-4 md:mt-0 text-sm">
          <li><a href="/privacypolicy" class="hover:text-white">Privacy Policy</a></li>
          <li><a href="/termsofuse" class="hover:text-white">Terms of Service</a></li>
        </ul>
      </div>
    </div>
  </footer>
  )
};

export default FootTerms;