import { ALL_VIDEOS } from "./actionType";
const initialStore = [];

const AllVideos = (state = initialStore, action) => {
  switch (action.type) {
    case ALL_VIDEOS:
      return action.payload;
    default:
      return state;
  }
};

export default AllVideos;
