import { MY_MUSICS_INSERT } from "./actionType";
const initialStore = [];

const MyMusics = (state = initialStore, action) => {
  switch (action.type) {
    case MY_MUSICS_INSERT:
      return [...action.payload];
    default:
      return state;
  }
};

export default MyMusics;
