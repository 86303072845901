/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */

import React, { useEffect, useState } from "react";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import Sidebar from "../../Layout/Sidebar";
import "../../js/News/News.scss";
import { NavLink } from "react-router-dom";
import { ApiGet } from "../../../helpers/API/ApiData";
import renderHTML from "react-render-html";
import FootTerms from "@/commonComs/FootTerms";

export default function News() {
  const [loading, setLoading] = useState(false);
  const [newsData, setNewsData] = useState();

  useEffect(() => {
    const getAllNews = async () => {
      setLoading(true);
      await ApiGet("news/get-all-news")
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data.data);
            setNewsData(res.data.data);
            setLoading(true);
          } else {
            console.log("error");
          }
        })
        .catch((err) => {
          setLoading(true);

          console.log(err);
        });
    };
    getAllNews();
  }, []);

  return (
    <div>
      <>
        <Header />
      </>
      <div className="flex">
        <>
          <Sidebar />
        </>
        <main className="s-layout__content" style={{ paddingBottom: "5%" }}>
          {newsData ? (
            newsData
              ?.slice(0)
              ?.reverse()
              .map((data, key) => {
                return (
                  <div className="main-box m-5 rounded">
                    <div className="container-fluid new-fix-width">
                      <div className="md:flex p-4 pb-3">
                        <div className="md:w-full">
                          <div className="">
                            <p className="font-size-35 white-text-color adobe-font font-medium tracking-normal text-center">
                              <strong>{data?.title}</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                      <hr />
                      {/* <ReactPlayer url={data?.video} controls={true} /> */}
                      <div className="video-grid pt-7">
                        <div className="video-grid-items">
                          <div className="video-images pb-7 mobile-pb-7">
                            <center>
                              <img
                                src={data?.image}
                                width="500px"
                                height="250px"
                              />
                            </center>
                          </div>
                          <div className="pt-7">
                            <hr />
                          </div>
                          <div className="video-images d-flex align-center pt-7 mobile-pt-7">
                            <center>
                              <video
                                src={`${data?.video}#t=00:00:05`}
                                controls={true}
                                height="250px"
                              />
                            </center>
                          </div>
                        </div>
                        <div className="video-grid-items">
                          <p className="font-size-16 white-text-color font-medium tracking-normal text-left">
                            <center>
                              {/* <ReactPlayer
                                url={data?.description}
                                controls={true}
                                height="240px"
                                width="500px"
                              /> */}
                            </center>
                            <div className="pt-3">
                              {renderHTML(data?.description)}
                            </div>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
          ) : loading ? (
            <center>
              <div className="mt-36">
                <div className="dots">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>
            </center>
          ) : (
            <center>
              <p className="font-size-35 mt-20 text-white">No Latest News!!</p>
            </center>
          )}
        </main>
      </div>
      <FootTerms />
    </div>
  );
}
