/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */

import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  ApiGet,
  ApiPost,
  ApiPut,
  ApiDelete,
} from "../../../helpers/API/ApiData";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../js/Playlist/playlist.scss";
import Header from "../../Layout/Header";
import Sidebar from "../../Layout/Sidebar";
import { Tooltip } from "@material-ui/core";
import { Dropdown } from "react-bootstrap";
import * as authUtil from "../../../utils/auth.util";
import * as userUtil from "../../../utils/user.util";
import { getMusicData } from "../../../store/reducers/MusicData/action";
import { useSelector, useDispatch } from "react-redux";
import { getMyMusics } from "../../../store/reducers/MyMusics/action";
import * as userUtils from "../../../utils/user.util";

export default function MyMusics() {
  const userId = userUtils.getUserInfo()?.user?.id;
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dId, setDownloadId] = useState();
  const [userPlaylist, setUserPlaylist] = useState([]);
  const myMusics = useSelector((state) => state.MyMusics);

  const handleAddToPlaylist = async (id) => {
    const data = {
      playlistId: id,
      musicId: playlistModal.musicId,
    };
    setLoading(true);
    await ApiPost("playlist/add-to-playlist", data)
      .then((res) => {
        setLoading(false);
        if (res.data.message.includes("exists")) {
          toast.error("Song is already added to the playlist!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            closeOnTouchStart: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          setPlaylistModal({ open: false });
          toast.success("Song is added to the playlist!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            closeOnTouchStart: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  const downloadMusic = async (id) => {
    console.log("Downloading");
    await ApiGet(`music/download?musicId=${id}`)
      .then((res) => {
        console.log("Downloads", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleSendEmail = async (id) => {
    const bodyId = {
      musicId: id,
      userId: userId,
    };
    await ApiPost(`downloadUser/downloadUsers`, bodyId)
      .then((res) => {
        console.log("Downloads", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handledownload = async (data) => {
    let url = data.musicUrl;
    let title = data?.musicTitle ? data?.musicTitle : data?.title;
    toast.success("Your download will begin shortly");

    await axios({
      url: url,
      method: "GET",
      responseType: "blob",
      headers: {
        "Content-Type": "video/mpeg",
      },
    })
      .then(async (response) => {
        handleSendEmail(data._id);
        console.log("object");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${title}.mp3`); //or any other extension
        document.body.appendChild(link);
        downloadUserList(data);
        await downloadMusic(data._id);
        link.click();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const downloadUserList = async (data) => {
    const body = {
      musicId: data._id,
      userId: userId,
      artistId: data.artistId._id,
    };

    await ApiPost(
      `musicDownloadListForArtist/create-musicDownloadListForArtist`,
      body
    )
      .then((res) => {
        console.log("", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const [playlistModal, setPlaylistModal] = useState({
    open: false,
    musicId: null,
  });

  const shareLink = (id) => {
    navigator.clipboard.writeText(`${window.location.href}song/${id}`);
    toast.success("Link Copied..");
  };
  const [copied, setCopied] = useState(false);
  function copy() {
    const el = document.createElement("input");
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  }

  const dispatch = useDispatch();
  useSelector((state) => {});

  useEffect(() => {
    console.log("My ooool Musics", myMusics);
    if (myMusics?.length === 0 || myMusics === undefined) {
      console.log(" in use data ------------>>>>>>>>>>>");
      getUserData();
    }
  }, []);

  const getUserData = async () => {
    await ApiGet("users/get-my-profile")
      .then((res) => {
        console.log("User Data", res.data.data);
        dispatch(getMyMusics(res.data.data.musicPurchased));
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getByUserPlaylist();
  }, []);

  const getByUserPlaylist = async () => {
    await ApiGet("playlist/get-by-user")
      .then((res) => {
        if (res.status.data !== "Record list is empty") {
          setUserPlaylist(res.data.data);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div style={{ paddingBottom: "6%" }}>
      <>
        <Header />
      </>
      <div className="flex">
        <>
          <Sidebar />
        </>
        <main className="s-layout__content">
          <div className="main-box">
            <div className="container-fluid">
              <div className="md:flex pt-24  pt-24-mobile">
                <div className="md:w-full pt-4 pl-3 pr-3 pt-5-mobile">
                  <div className="flex items-center ">
                    <NavLink to="/" onClick={(e) => {
                      e.preventDefault();
                      history(-1)
                    }}>
                      <p>
                        <i className="fas fa-arrow-left cursor-pointer icon-font-size text-white"></i>
                      </p>
                    </NavLink>
                    <p className="pl-3 font-size-25 white-text-color font-bold tracking-normal cursor-pointer">
                      My Music
                    </p>
                  </div>
                </div>
              </div>

              {playlistModal.open ? (
                <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                      {/*content*/}
                      <div className="playlist border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div
                          className="justify-content-center p-5 relative  border-solid border-white-200 rounded-t"
                          // style={{
                          //   backgroundColor: "#090C10",
                          // }}
                        >
                          <h3 className="text-2xl text-center font-bold text-white">
                            Select Playlist
                          </h3>

                          <button
                            className="p-1 ml-auto bg-transparent border-0 text-white  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }
                            OnTouchStart={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }
                          >
                            <p className="button-center-modal">
                              <i className="fas fa-times"></i>
                            </p>
                          </button>
                        </div>
                        {/*body*/}
                        <div
                          className="relative p-6 flex-auto"
                          // style={{
                          //   backgroundColor: "#090C10",
                          // }}
                        >
                          <p className="my-4 text-white text-lg leading-relaxed">
                            <div
                              className="flex items-center flex-row flex-wrap pt-4"
                              style={{
                                height: "450px",
                                overflowY: "overlay",
                              }}
                            >
                              {userPlaylist && userPlaylist.length ? (
                                userPlaylist.map((record, index) => {
                                  return (
                                    <div
                                      className="pl-3 pr-3 cursor-pointer "
                                      key={index}
                                      onClick={() => {
                                        handleAddToPlaylist(record._id);
                                      }}
                                      OnTouchStart={() => {
                                        handleAddToPlaylist(record._id);
                                      }}
                                    >
                                      <div className="card-holder">
                                        <div className="singer-box">
                                          <div className="singer-img">
                                            <img
                                              src={
                                                (record &&
                                                  record.Songs &&
                                                  record.Songs.length &&
                                                  record.Songs[0].musicImage) ||
                                                `https://source.unsplash.com/1600x900/?${record.playlist_name}`
                                              }
                                              style={{
                                                height: "150px",
                                                width: "335px",
                                              }}
                                              alt=""
                                            />
                                          </div>
                                          <div className="singer-text p-4">
                                            <div className="flex items-center justify-between">
                                              <div>
                                                <p className="font-size-14 white-text-color font-normal tracking-normal mb-0">
                                                  {record.playlist_name}
                                                </p>
                                              </div>
                                              <div>
                                                {record?.Songs?.map((rec) => {
                                                  if (
                                                    rec._id ===
                                                    playlistModal.musicId
                                                  ) {
                                                    return (
                                                      <>
                                                        <p className="cursor-pointer font-size-14 login-text-color">
                                                          added
                                                          <i className="pl-1 fas fa-check cursor-pointer font-size-14 login-text-color"></i>
                                                        </p>
                                                      </>
                                                    );
                                                  }
                                                })}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="justify-content-center widthFull">
                                  <h1 className="text-white text-2xl mt-10">
                                    Playlist Is Empty.
                                  </h1>
                                </div>
                              )}
                            </div>
                          </p>
                        </div>
                        {/*footer*/}
                        <div
                          className="flex items-center justify-end p-4  border-solid border-white  "
                          // style={{
                          //   backgroundColor: "#090C10",
                          // }}
                        >
                          <button
                            className="closebtn text-white border-solid border border-white font-bold uppercase px-6 py-2 text-sm  mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }
                            OnTouchStart={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
              ) : null}

              {!myMusics?.length && (
                <div className="dots">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              )}
              <div className="lg:flex pt-4 scrolling-wrapper bottom-align-grid">
                {myMusics && myMusics.length
                  ? myMusics?.map((record, index) => {
                      return (
                        <div
                          key={index}
                          className="lg:w-1/5 pl-3 pr-3 card mobile-pb-4"
                        >
                          <div className="banner-box">
                            <div className="banner-img">
                              <img
                                className="object-cover"
                                src={record?.musicImage}
                                alt={record?.musicTitle}
                                onClick={() =>
                                  record.isPaid
                                    ? authUtil.isLoggedIn() &&
                                      userUtil
                                        .getMusicData()
                                        .includes(record._id) &&
                                      dispatch(getMusicData(record))
                                    : dispatch(getMusicData(record))
                                }
                                OnTouchStart={() =>
                                  record.isPaid
                                    ? authUtil.isLoggedIn() &&
                                      userUtil
                                        .getMusicData()
                                        .includes(record._id) &&
                                      dispatch(getMusicData(record))
                                    : dispatch(getMusicData(record))
                                }
                              />
                            </div>
                            <div className="singer-text p-5">
                              <div className="flex cursor-pointer">
                                <div className="flex justify-between line-nowrap">
                                  <p className="font-size-18 white-text-color font-normal tracking-normal mb-0">
                                    {record?.musicTitle}
                                  </p>
                                  <div className=" ">
                                    <div>
                                    <div className="My_Dropdown ">
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          variant="success"
                                          id="dropdown-basic"
                                          className="text-white font-size-10"
                                        >
                                          {" "}
                                          <i className="fas fa-ellipsis-v cursor-pointer font-size-10 text-white p-2"></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <div
                                            className="flex flex-col w-32 rounded box-shadow-cus max-w-full border-color"
                                            style={{
                                              backgroundColor: "#090C10",
                                            }}
                                          >
                                            <div
                                              className="text-white p-2 cursor-pointer"
                                              onClick={() =>
                                                setPlaylistModal({
                                                  musicId: record._id,
                                                  open: true,
                                                })
                                              }
                                              OnTouchStart={() =>
                                                setPlaylistModal({
                                                  musicId: record._id,
                                                  open: true,
                                                })
                                              }
                                            >
                                              Add to playlist
                                            </div>

                                            <div
                                              className="text-white p-2 cursor-pointer"
                                              onClick={() =>
                                                dispatch(getMusicData(record))
                                              }
                                              OnTouchStart={() =>
                                                dispatch(getMusicData(record))
                                              }
                                            >
                                              Play song now
                                            </div>

                                            <div
                                              className="text-white p-2 cursor-pointer"
                                              onClick={() =>
                                                history(`/song/${record._id}`)
                                              }
                                              OnTouchStart={() =>
                                                history(`/song/${record._id}`)
                                              }
                                            >
                                              Details
                                            </div>

                                            <div
                                              className="text-white p-2 cursor-pointer"
                                              onClick={() => {
                                                setDownloadId(record._id);
                                                authUtil.isLoggedIn() ? (
                                                  handledownload(record)
                                                ) : (
                                                  <></>
                                                );
                                              }}
                                              OnTouchStart={() => {
                                                setDownloadId(record._id);
                                                authUtil.isLoggedIn() ? (
                                                  handledownload(record)
                                                ) : (
                                                  <></>
                                                );
                                              }}
                                            >
                                              Download
                                            </div>
                                            <div
                                              className="text-white p-2 cursor-pointer"
                                              onClick={() => {
                                                shareLink(record._id);
                                              }}
                                              OnTouchStart={() => {
                                                shareLink(record._id);
                                              }}
                                            >
                                              <button onClick={copy} OnTouchStart={copy}>
                                                {!copied
                                                  ? "Copy link"
                                                  : "Copy link"}
                                              </button>
                                            </div>
                                          </div>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
