/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */


import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { getUserInfo } from "../../../utils/user.util";
import "../../js/Playlist/playlist.scss";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import Sidebar from "../../Layout/Sidebar";
import { ApiGet } from "./../../../helpers/API/ApiData";
import { useSelector, useDispatch } from "react-redux";
import { getPlaylist } from "../../../store/reducers/AllPlaylist/action";
import { getMusicData } from "../../../store/reducers/MusicData/action";
import * as authUtil from "../../../utils/auth.util";

export default function PlayListGlobal() {
  const user = getUserInfo();
  const history = useNavigate();
  const [loginModal, setLoginModal] = useState(false);
  const [showModal, setShowModal] = useState(false);



  const allPlaylist = useSelector((state) => state.AllPlaylist);
  const dispatch = useDispatch();
  useEffect(() => {
    if (allPlaylist?.length === 0 || 5) {
      getAllPlaylist();
    }
  }, []);

  const getAllPlaylist = async () => {
    await ApiGet(`playlist/get-all-playlist/${null}`)
      .then((res) => {
        if (res.status.data !== "Record list is empty") {
          dispatch(getPlaylist(res.data.data));
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // play all free songs on a list one by one from page to page 
  const handlePlayallPlaylist = (record, key) => {
    if (record.isPaid) {
       if (authUtil.isLoggedIn()) {
        setShowModal({
          musicId: record._id,
          open: true,
          amount: record.amount,
          musicTitle: record.title,
          artistId: record.artistData?._id,
        });
       } else {
        setLoginModal(true);
       }
    } else {
      dispatch(getMusicData({playlistData: allPlaylist, index: key}));
    }

  return (
    <div>
      <>
        <Header />
      </>
      <div className="flex">
        <>
          <Sidebar />
        </>
        <main className="s-layout__content">
          <div className="main-box">
            <div className="container-fluid">
              <div className="md:flex pt-24  pt-24-mobile">
                <div className="md:w-full pt-4 pl-3 pr-3 pt-5-mobile">
                  <div className="flex items-center">
                    <NavLink to="/" onClick={(e) => {
                      e.preventDefault();
                      history(-1)
                    }}
                    OnTouchStart={(e) => {
                      e.preventDefault();
                      history(-1)
                    }}
                    >
                      <p>
                        <i className="fas fa-arrow-left cursor-pointer icon-font-size text-white"></i>
                      </p>
                    </NavLink>
                    <p className="pl-3 font-size-35 white-text-color font-bold tracking-normal cursor-pointer">
                      Global Playlist
                    </p>
                  </div>
                </div>
              </div>
              {!allPlaylist?.length && (
                <div className="dots mt-10">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              )}
              <div className="cus-grid pt-4">
                {allPlaylist && allPlaylist.length
                  ? allPlaylist?.map((record, index) => {
                      if (!record.isPrivate) {
                        return (
                          <div className="grid-items" key={index}>
                            <div className="singer-box">
                              <div
                                className="singer-img"
                                // onClick={() => {
                                //   history(`/play/${record._id}`);
                                // }}
                                
                                // className="banner-img"
                                    onClick={() =>
                                      handlePlayallPlaylist(record, index)}    // play all free songs on a list one by one from page to page
                                    
                                    OnTouchStart={() => 
                                      handlePlayallPlaylist(record, index)}
                              >
                                <img
                                  src={
                                    (record &&
                                      record.Songs &&
                                      record.Songs.length &&
                                      record.Songs[0].musicImage) ||
                                    `https://source.unsplash.com/1600x900/?${record.playlist_name}`
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="singer-text p-5 mini-view-p-remove">
                                <div className="flex items-center justify-between">
                                  <div
                                    onClick={() => {
                                      history(`/play/${record._id}`);
                                    }}
                                    OnTouchStart={() => {
                                      history(`/play/${record._id}`);
                                    }}
                                  >
                                    <p className="font-size-18 white-text-color font-normal tracking-normal mb-0">
                                      {record.playlist_name}
                                    </p>
                                  </div>
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })
                  : ""}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}};
