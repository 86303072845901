import React from "react";
import "./LoadingBalls.css";
const LoadingBalls = () => {
  return (
    <div className="load-balls">
      <hr />
      <hr />
      <hr />
      <hr />
    </div>
  );
};

export default LoadingBalls;
