/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */

import { useElements, useStripe, CardElement } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { ApiPost } from "../../../helpers/API/ApiData";
import CardInput from "./CardInput";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingBalls from "../../../Assets/LoadingBalls";
import { getUserInfo, setMusicPurchased } from "../../../utils/user.util";
import "../../js/Payments/payment.scss";

const Payments = (props) => {
  const userInfo = getUserInfo();
  console.log("dsfsdfsgfdsgdsbgdsf", props.service);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const stripe = useStripe();
  const elements = useElements();

  const handleDataChange = (e) => {
    let { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validate = () => {
    let error = [];
    let formIsValid = true;

    if (!formData.email) {
      formIsValid = false;
      error["email"] = "Please Enter Email";
    }
    if (!formData.address) {
      formIsValid = false;
      error["address"] = "Please Enter address";
    }
    if (!formData.firstName) {
      formIsValid = false;
      error["firstName"] = "Please Enter firstName";
    }
    if (!formData.lastName) {
      formIsValid = false;
      error["lastName"] = "Please Enter lastName";
    }
    if (!formData.zip) {
      formIsValid = false;
      error["zip"] = "Please Enter zip";
    }
    if (!formData.country) {
      formIsValid = false;
      error["country"] = "Please Enter country";
    }
    setErrors(error);
    return formIsValid;
  };

  const handlePurchaseAPI = async () => {
    const body = {
      userId: userInfo?.user?.id,
      musicId: props.musicId,
    };
    await ApiPost(`purchase/purchaseUser`, body)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSubmit = async (event) => {
    if (validate()) {
      if (!stripe || !elements) {
        return;
      }
      setLoading(true);
      const res = await ApiPost("payment/pay", {
        email: formData.email,
        amount: props.amount,
      });

      const clientSecret = res.data.data["client_secret"];
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            email: formData.email,
          },
        },
      });
      if (result.error) {
        setLoading(false);
        // Show error to your customer (e.g., insufficient funds)
        console.log(result.error.message);
      } else {
        // The payment has been processed!
        if (result.paymentIntent.status === "succeeded") {
          const res = await ApiPost("payment/confirmPayment", {
            email: formData.email,
            productId: props.musicId || props.productId,
            amount: props.amount,
            type: props.musicId ? "music" : "product",
            size: props.size || null,
            country: formData.country,
            zip: formData.zip,
            address: formData.address,
            firstName: formData.firstName,
            lastName: formData.lastName,
            quantity: props.quantity || null,
            service: props.service ? props.service : null,
            paymentIntentId: clientSecret,
          });

          setLoading(false);
          console.log("Money is in the bank!");
          toast.success("Payment successfully completed!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            closeOnTouchStart: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setMusicPurchased(props.musicId);

          handlePurchaseAPI();
          // Show a success message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.
        }
      }
    }
  };

  return (
    <div>
      <form>
        <div className="flex flex-col items-center justify-center pb-3">
          <div className="flex my-2">
            <div className="flex flex-col items-start">
              <p
                style={{
                  color: "red",
                  top: "0px",
                  fontSize: "12px",
                }}
                className="mr-2"
              >
                {errors["firstName"]}
              </p>
              <input
                type="text"
                style={{
                  backgroundColor: "transparent",
                  borderColor: "green",
                  color: "#fff",
                  padding: "10px 15px",
                  width: "100%",
                }}
                placeholder="Enter First Name"
                name="firstName"
                className="rounded-lg border border-primary font-size-18 mr-2"
                onChange={(e) => handleDataChange(e)}
              />
            </div>
            <div className="flex flex-col items-start">
              <p
                style={{
                  color: "red",
                  top: "0px",
                  fontSize: "12px",
                }}
                className="ml-2"
              >
                {errors["lastName"]}
              </p>
              <input
                type="text"
                style={{
                  backgroundColor: "transparent",
                  borderColor: "green",
                  color: "#fff",
                  padding: "10px 15px",
                  width: "100%",
                }}
                placeholder="Enter Last Name "
                name="lastName"
                className="rounded-lg border border-primary font-size-18 ml-2"
                onChange={(e) => handleDataChange(e)}
              />
            </div>
          </div>
          <div className="flex my-2">
            <div className="flex flex-col items-start">
              <p
                style={{
                  color: "red",
                  top: "0px",
                  fontSize: "12px",
                }}
                className="mr-2"
              >
                {errors["country"]}
              </p>
              <input
                type="text"
                style={{
                  backgroundColor: "transparent",
                  borderColor: "green",
                  color: "#fff",
                  padding: "10px 15px",
                  width: "100%",
                }}
                placeholder="Enter Country"
                name="country"
                className="rounded-lg border border-primary font-size-18 mr-2"
                onChange={(e) => handleDataChange(e)}
              />
            </div>
            <div className="flex flex-col items-start">
              <p
                style={{
                  color: "red",
                  top: "0px",
                  fontSize: "12px",
                }}
                className="ml-2"
              >
                {errors["zip"]}
              </p>
              <input
                type="number"
                defaultValue="00000"
                min="0"
                style={{
                  backgroundColor: "transparent",
                  borderColor: "green",
                  color: "#fff",
                  padding: "10px 15px",
                  width: "100%",
                }}
                placeholder="Enter zip"
                name="zip"
                className="rounded-lg border border-primary font-size-18 ml-2"
                onChange={(e) => handleDataChange(e)}
              />
            </div>
          </div>
          <p
            style={{
              color: "red",
              top: "0px",
              fontSize: "12px",
            }}
          >
            {errors["address"]}
          </p>
          <input
            type="text"
            style={{
              color: "#fff",
              backgroundColor: "transparent",
              borderColor: "green",
              padding: "10px 15px",
              width: "100%",
            }}
            placeholder="Enter Address"
            name="address"
            className="rounded-lg border border-primary font-size-18 my-2"
            onChange={(e) => handleDataChange(e)}
          />
          <p
            style={{
              color: "red",
              top: "0px",
              fontSize: "12px",
            }}
            className="text-left"
          >
            {errors["email"]}
          </p>
          <input
            type="email"
            style={{
              color: "#fff",
              backgroundColor: "transparent",
              borderColor: "green",
              padding: "10px 15px",
              width: "100%",
            }}
            placeholder="Enter Email"
            name="email"
            className="rounded-lg border border-primary font-size-18 my-2"
            onChange={(e) => handleDataChange(e)}
          />
        </div>
      </form>
      <div className="my-4">
        <CardInput />
      </div>
      <div className="flex items-center justify-end p-2 pt-2 pb-0 rounded-b">
        <button
          className="purchasebtn bg-success text-white  rounded font-bold uppercase px-6 py-2 text-sm  mr-1 mb-1 ease-linear transition-all duration-150"
          onClick={() => handleSubmit()}
          OnTouchStart={() => handleSubmit()}

          // disabled={isDisabled}
        >
          Purchase
        </button>
      </div>
      {loading && <LoadingBalls />}
    </div>
  );
};

export default Payments;
