/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */

import React from "react";
import { createBrowserRouter } from "react-router-dom";
import "../App.css";
import "../Components/styles/tailwind.scss";
import "../Components/styles/main.scss";
import Dashboard from "./js/Dashboard";
import PlayList from "./js/Playlist/PlayList";
import Play from "./js/Playlist/Play";
import Artist from "./js/Artist/Artist";
import Trending from "./js/Trending/Trending";
import NewRelease from "./js/NewRelease/NewRelease";
import MusicByCountry from "./js/MusicByCountry/MusicByCountry";
import Music from "./Music/Music";
import News from "./js/News/News";
import Heading from "./js/News/Heading";
import Profile from "./js/Profile/Profile";
import UserProfile from "./js/Profile/UserProfile";
import Plan from "./Plan/Plan";
import Audio from "./js/Audio/Audio";
import MixierDetails from "./MixerDetails/MixierDetails";
import Shop from "./Shop/Shop";
import HeadingChild from "./js/News/HeadingChild";
import HeadingText from "./js/News/HeadingText";
import FreeDownload from "./js/FreeDownload/FreeDownload";
import Album from "./js/Album/Album";
import ShopCart from "./Shop/ShopCart";
import Product from "./Shop/Product";
import Cart from "./js/Cart/Cart";
import MusicGenre from "./js/MusicMood/MusicGenre";
import CreatePlaylist from "./js/Playlist/CreatePlaylist";
import PlayListGlobal from "./js/Playlist/PlaylistGlobal";
import MyOrders from "./js/MyOrders/MyOrders";
import Search from "./js/Search/Search";
import Song from "./js/Song/Song";
import Footer from "./Layout/Footer";
import AllMusicByCountry from "./js/MusicByCountry/AllMusicByCountry";
import ContentUsePolicy from "./js/ContentUsePolicy/ContentUsePolicy";
import Video from "./js/Video/Video";
import Questions from "./questions/questions";
import Changepassword from "./Layout/forgotpassword";
import Header from "./Layout/Header";
import Privacypolicy from "./privacypolicy/privacypolicy";
import AlbumPlay from "./js/Album/AlbumPlay";
import Termsofuse from "./js/TermsConditions/TermsConditions";
import MusicByGenre from "./js/MusicMood/MusicByMood";
import MyMusics from "./js/MyMusics/MyMusics";
import NewServices from "./js/Service/ServicesNew";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Dashboard />
        <Footer />
      </>
    ),
  },
  {
    path: "/playlist",
    element: (
      <>
        <PlayList />
        <Footer />
      </>
    ),
  },
  {
    path: "/mymusics",
    element: (
      <>
        <MyMusics />
        <Footer />
      </>
    ),
  },
  {
    path: "/video",
    element: (
      <>
        <Video />
        <Footer />
      </>
    ),
  },
  {
    path: "/play",
    element: (
      <>
        <Play />
        <Footer />
      </>
    ),
  },
  {
    path: "/artist",
    element: (
      <>
        <Artist />
        <Footer />
      </>
    ),
  },
  {
    path: "/trending",
    element: (
      <>
        <Trending />
        <Footer />
      </>
    ),
  },
  {
    path: "/newrelease",
    element: (
      <>
        <NewRelease />
        <Footer />
      </>
    ),
  },
  {
    path: "/allcountrymusic",
    element: (
      <>
        <AllMusicByCountry />
        <Footer />
      </>
    ),
  },
  {
    path: "/musicbycountry/:name",
    element: (
      <>
        <MusicByCountry />
        <Footer />
      </>
    ),
  },
  {
    path: "/freedownloads",
    element: (
      <>
        <FreeDownload />
        <Footer />
      </>
    ),
  },
  {
    path: "/freshalbums",
    element: (
      <>
        <Album />
        <Footer />
      </>
    ),
  },
  {
    path: "/albumplay/:id",
    element: (
      <>
        <AlbumPlay />
        <Footer />
      </>
    ),
  },
  {
    path: "/play/:id",
    element: (
      <>
        <Play />
        <Footer />
      </>
    ),
  },
  {
    path: "/music",
    element: (
      <>
        <Music />
        <Footer />
      </>
    ),
  },
  {
    path: "/news",
    element: (
      <>
        <News />
        <Footer />
      </>
    ),
  },
  {
    path: "/heading",
    element: (
      <>
        <Heading />
        <Footer />
      </>
    ),
  },
  {
    path: "/headingchild",
    element: (
      <>
        <HeadingChild />
        <Footer />
      </>
    ),
  },
  {
    path: "/service",
    element: (
      <>
        <NewServices />
        <Footer />
      </>
    ),
  },
  {
    path: "/profile/:id",
    element: (
      <>
        <Profile />
        <Footer />
      </>
    ),
  },
  {
    path: "/userprofile",
    element: (
      <>
        <UserProfile />
        <Footer />
      </>
    ),
  },
  {
    path: "/plan",
    element: (
      <>
        <Plan />
        <Footer />
      </>
    ),
  },
  {
    path: "/audio",
    element: (
      <>
        <Audio />
        <Footer />
      </>
    ),
  },
  {
    path: "/mixierdetails",
    element: (
      <>
        <MixierDetails />
        <Footer />
      </>
    ),
  },
  {
    path: "/shop",
    element: (
      <>
        <Shop />
        <Footer />
      </>
    ),
  },
  {
    path: "/headingtext",
    element: (
      <>
        <HeadingText />
        <Footer />
      </>
    ),
  },
  {
    path: "/shopcart",
    element: (
      <>
        <ShopCart />
        <Footer />
      </>
    ),
  },
  {
    path: "/product",
    element: (
      <>
        <Product />
        <Footer />
      </>
    ),
  },
  {
    path: "/cart/:id",
    element: (
      <>
        <Cart />
        <Footer />
      </>
    ),
  },
  {
    path: "/musicbygenre",
    element: (
      <>
        <MusicByGenre />
        <Footer />
      </>
    ),
  },
  {
    path: "/music/:id",
    element: (
      <>
        <MusicGenre />
        <Footer />
      </>
    ),
  },
  {
    path: "/create-playlist",
    element: (
      <>
        <CreatePlaylist />
        <Footer />
      </>
    ),
  },
  {
    path: "/global-playlist",
    element: (
      <>
        <PlayListGlobal />
        <Footer />
      </>
    ),
  },
  {
    path: "/myorders",
    element: (
      <>
        <MyOrders />
        <Footer />
      </>
    ),
  },
  {
    path: "/search",
    element: (
      <>
        <Search />
        <Footer />
      </>
    ),
  },
  {
    path: "/song/:id",
    element: (
      <>
        <Song />
        <Footer />
      </>
    ),
  },
  {
    path: "/termsofuse",
    element: (
      <>
        <Termsofuse />
        <Footer />
      </>
    ),
  },
  {
    path: "/privacypolicy",
    element: (
      <>
        <Privacypolicy />
        <Footer />
      </>
    ),
  },
  {
    path: "/onboarding-policy",
    element: (
      <>
        <ContentUsePolicy />
        <Footer />
      </>
    ),
  },
  {
    path: "/questions",
    element: (
      <>
        <Questions />
        <Footer />
      </>
    ),
  },
  {
    path: "/changepassword",
    element: (
      <>
        <Changepassword />
        <Footer />
      </>
    ),
  },
  {
    path: "/header",
    element: (
      <>
        <Header />
        <Footer />
      </>
    ),
  },
]);
