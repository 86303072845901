
const Country = {
  Country: [
    {
      Name: "Afghanistan",
      Idc: "+93",
      Code: "AF",
    },
    {
      Name: "Aland Islands",
      Idc: "+358",
      Code: "AX",
    },
    {
      Name: "Albania",
      Idc: "+355",
      Code: "AL",
    },
    {
      Name: "Algeria",
      Idc: "+213",
      Code: "DZ",
    },
    {
      Name: "AmericanSamoa",
      Idc: "+1684",
      Code: "AS",
    },
    {
      Name: "Andorra",
      Idc: "+376",
      Code: "AD",
    },
    {
      Name: "Angola",
      Idc: "+244",
      Code: "AO",
    },
    {
      Name: "Anguilla",
      Idc: "+1264",
      Code: "AI",
    },
    {
      Name: "Antarctica",
      Idc: "+672",
      Code: "AQ",
    },
    {
      Name: "Antigua and Barbuda",
      Idc: "+1268",
      Code: "AG",
    },
    {
      Name: "Argentina",
      Idc: "+54",
      Code: "AR",
    },
    {
      Name: "Armenia",
      Idc: "+374",
      Code: "AM",
    },
    {
      Name: "Aruba",
      Idc: "+297",
      Code: "AW",
    },
    {
      Name: "Australia",
      Idc: "+61",
      Code: "AU",
    },
    {
      Name: "Austria",
      Idc: "+43",
      Code: "AT",
    },
    {
      Name: "Azerbaijan",
      Idc: "+994",
      Code: "AZ",
    },
    {
      Name: "Bahamas",
      Idc: "+1242",
      Code: "BS",
    },
    {
      Name: "Bahrain",
      Idc: "+973",
      Code: "BH",
    },
    {
      Name: "Bangladesh",
      Idc: "+880",
      Code: "BD",
    },
    {
      Name: "Barbados",
      Idc: "+1246",
      Code: "BB",
    },
    {
      Name: "Belarus",
      Idc: "+375",
      Code: "BY",
    },
    {
      Name: "Belgium",
      Idc: "+32",
      Code: "BE",
    },
    {
      Name: "Belize",
      Idc: "+501",
      Code: "BZ",
    },
    {
      Name: "Benin",
      Idc: "+229",
      Code: "BJ",
    },
    {
      Name: "Bermuda",
      Idc: "+1441",
      Code: "BM",
    },
    {
      Name: "Bhutan",
      Idc: "+975",
      Code: "BT",
    },
    {
      Name: "Bolivia, Plurinational State of",
      Idc: "+591",
      Code: "BO",
    },
    {
      Name: "Bosnia and Herzegovina",
      Idc: "+387",
      Code: "BA",
    },
    {
      Name: "Botswana",
      Idc: "+267",
      Code: "BW",
    },
    {
      Name: "Brazil",
      Idc: "+55",
      Code: "BR",
    },
    {
      Name: "British Indian Ocean Territory",
      Idc: "+246",
      Code: "IO",
    },
    {
      Name: "Brunei Darussalam",
      Idc: "+673",
      Code: "BN",
    },
    {
      Name: "Bulgaria",
      Idc: "+359",
      Code: "BG",
    },
    {
      Name: "Burkina Faso",
      Idc: "+226",
      Code: "BF",
    },
    {
      Name: "Burundi",
      Idc: "+257",
      Code: "BI",
    },
    {
      Name: "Cambodia",
      Idc: "+855",
      Code: "KH",
    },
    {
      Name: "Cameroon",
      Idc: "+237",
      Code: "CM",
    },
    {
      Name: "Canada",
      Idc: "+1",
      Code: "CA",
    },
    {
      Name: "Cape Verde",
      Idc: "+238",
      Code: "CV",
    },
    {
      Name: "Cayman Islands",
      Idc: "+ 345",
      Code: "KY",
    },
    {
      Name: "Central African Republic",
      Idc: "+236",
      Code: "CF",
    },
    {
      Name: "Chad",
      Idc: "+235",
      Code: "TD",
    },
    {
      Name: "Chile",
      Idc: "+56",
      Code: "CL",
    },
    {
      Name: "China",
      Idc: "+86",
      Code: "CN",
    },
    {
      Name: "Christmas Island",
      Idc: "+61",
      Code: "CX",
    },
    {
      Name: "Cocos (Keeling) Islands",
      Idc: "+61",
      Code: "CC",
    },
    {
      Name: "Colombia",
      Idc: "+57",
      Code: "CO",
    },
    {
      Name: "Comoros",
      Idc: "+269",
      Code: "KM",
    },
    {
      Name: "Congo",
      Idc: "+242",
      Code: "CG",
    },
    {
      Name: "Congo, The Democratic Republic of the Congo",
      Idc: "+243",
      Code: "CD",
    },
    {
      Name: "Cook Islands",
      Idc: "+682",
      Code: "CK",
    },
    {
      Name: "Costa Rica",
      Idc: "+506",
      Code: "CR",
    },
    {
      Name: "Cote d'Ivoire",
      Idc: "+225",
      Code: "CI",
    },
    {
      Name: "Croatia",
      Idc: "+385",
      Code: "HR",
    },
    {
      Name: "Cuba",
      Idc: "+53",
      Code: "CU",
    },
    {
      Name: "Cyprus",
      Idc: "+357",
      Code: "CY",
    },
    {
      Name: "Czech Republic",
      Idc: "+420",
      Code: "CZ",
    },
    {
      Name: "Denmark",
      Idc: "+45",
      Code: "DK",
    },
    {
      Name: "Djibouti",
      Idc: "+253",
      Code: "DJ",
    },
    {
      Name: "Dominica",
      Idc: "+1767",
      Code: "DM",
    },
    {
      Name: "Dominican Republic",
      Idc: "+1849",
      Code: "DO",
    },
    {
      Name: "Ecuador",
      Idc: "+593",
      Code: "EC",
    },
    {
      Name: "Egypt",
      Idc: "+20",
      Code: "EG",
    },
    {
      Name: "El Salvador",
      Idc: "+503",
      Code: "SV",
    },
    {
      Name: "Equatorial Guinea",
      Idc: "+240",
      Code: "GQ",
    },
    {
      Name: "Eritrea",
      Idc: "+291",
      Code: "ER",
    },
    {
      Name: "Estonia",
      Idc: "+372",
      Code: "EE",
    },
    {
      Name: "Ethiopia",
      Idc: "+251",
      Code: "ET",
    },
    {
      Name: "Falkland Islands (Malvinas)",
      Idc: "+500",
      Code: "FK",
    },
    {
      Name: "Faroe Islands",
      Idc: "+298",
      Code: "FO",
    },
    {
      Name: "Fiji",
      Idc: "+679",
      Code: "FJ",
    },
    {
      Name: "Finland",
      Idc: "+358",
      Code: "FI",
    },
    {
      Name: "France",
      Idc: "+33",
      Code: "FR",
    },
    {
      Name: "French Guiana",
      Idc: "+594",
      Code: "GF",
    },
    {
      Name: "French Polynesia",
      Idc: "+689",
      Code: "PF",
    },
    {
      Name: "Gabon",
      Idc: "+241",
      Code: "GA",
    },
    {
      Name: "Gambia",
      Idc: "+220",
      Code: "GM",
    },
    {
      Name: "Georgia",
      Idc: "+995",
      Code: "GE",
    },
    {
      Name: "Germany",
      Idc: "+49",
      Code: "DE",
    },
    {
      Name: "Ghana",
      Idc: "+233",
      Code: "GH",
    },
    {
      Name: "Gibraltar",
      Idc: "+350",
      Code: "GI",
    },
    {
      Name: "Greece",
      Idc: "+30",
      Code: "GR",
    },
    {
      Name: "Greenland",
      Idc: "+299",
      Code: "GL",
    },
    {
      Name: "Grenada",
      Idc: "+1473",
      Code: "GD",
    },
    {
      Name: "Guadeloupe",
      Idc: "+590",
      Code: "GP",
    },
    {
      Name: "Guam",
      Idc: "+1671",
      Code: "GU",
    },
    {
      Name: "Guatemala",
      Idc: "+502",
      Code: "GT",
    },
    {
      Name: "Guernsey",
      Idc: "+44",
      Code: "GG",
    },
    {
      Name: "Guinea",
      Idc: "+224",
      Code: "GN",
    },
    {
      Name: "Guinea-Bissau",
      Idc: "+245",
      Code: "GW",
    },
    {
      Name: "Guyana",
      Idc: "+595",
      Code: "GY",
    },
    {
      Name: "Haiti",
      Idc: "+509",
      Code: "HT",
    },
    {
      Name: "Holy See (Vatican City State)",
      Idc: "+379",
      Code: "VA",
    },
    {
      Name: "Honduras",
      Idc: "+504",
      Code: "HN",
    },
    {
      Name: "Hong Kong",
      Idc: "+852",
      Code: "HK",
    },
    {
      Name: "Hungary",
      Idc: "+36",
      Code: "HU",
    },
    {
      Name: "Iceland",
      Idc: "+354",
      Code: "IS",
    },
    {
      Name: "India",
      Idc: "+91",
      Code: "IN",
    },
    {
      Name: "Indonesia",
      Idc: "+62",
      Code: "ID",
    },
    {
      Name: "Iran, Islamic Republic of Persian Gulf",
      Idc: "+98",
      Code: "IR",
    },
    {
      Name: "Iraq",
      Idc: "+964",
      Code: "IQ",
    },
    {
      Name: "Ireland",
      Idc: "+353",
      Code: "IE",
    },
    {
      Name: "Isle of Man",
      Idc: "+44",
      Code: "IM",
    },
    {
      Name: "Israel",
      Idc: "+972",
      Code: "IL",
    },
    {
      Name: "Italy",
      Idc: "+39",
      Code: "IT",
    },
    {
      Name: "Jamaica",
      Idc: "+1876",
      Code: "JM",
    },
    {
      Name: "Japan",
      Idc: "+81",
      Code: "JP",
    },
    {
      Name: "Jersey",
      Idc: "+44",
      Code: "JE",
    },
    {
      Name: "Jordan",
      Idc: "+962",
      Code: "JO",
    },
    {
      Name: "Kazakhstan",
      Idc: "+77",
      Code: "KZ",
    },
    {
      Name: "Kenya",
      Idc: "+254",
      Code: "KE",
    },
    {
      Name: "Kiribati",
      Idc: "+686",
      Code: "KI",
    },
    {
      Name: "Korea, Democratic People's Republic of Korea",
      Idc: "+850",
      Code: "KP",
    },
    {
      Name: "Korea, Republic of South Korea",
      Idc: "+82",
      Code: "KR",
    },
    {
      Name: "Kuwait",
      Idc: "+965",
      Code: "KW",
    },
    {
      Name: "Kyrgyzstan",
      Idc: "+996",
      Code: "KG",
    },
    {
      Name: "Laos",
      Idc: "+856",
      Code: "LA",
    },
    {
      Name: "Latvia",
      Idc: "+371",
      Code: "LV",
    },
    {
      Name: "Lebanon",
      Idc: "+961",
      Code: "LB",
    },
    {
      Name: "Lesotho",
      Idc: "+266",
      Code: "LS",
    },
    {
      Name: "Liberia",
      Idc: "+231",
      Code: "LR",
    },
    {
      Name: "Libyan Arab Jamahiriya",
      Idc: "+218",
      Code: "LY",
    },
    {
      Name: "Liechtenstein",
      Idc: "+423",
      Code: "LI",
    },
    {
      Name: "Lithuania",
      Idc: "+370",
      Code: "LT",
    },
    {
      Name: "Luxembourg",
      Idc: "+352",
      Code: "LU",
    },
    {
      Name: "Macao",
      Idc: "+853",
      Code: "MO",
    },
    {
      Name: "Macedonia",
      Idc: "+389",
      Code: "MK",
    },
    {
      Name: "Madagascar",
      Idc: "+261",
      Code: "MG",
    },
    {
      Name: "Malawi",
      Idc: "+265",
      Code: "MW",
    },
    {
      Name: "Malaysia",
      Idc: "+60",
      Code: "MY",
    },
    {
      Name: "Maldives",
      Idc: "+960",
      Code: "MV",
    },
    {
      Name: "Mali",
      Idc: "+223",
      Code: "ML",
    },
    {
      Name: "Malta",
      Idc: "+356",
      Code: "MT",
    },
    {
      Name: "Marshall Islands",
      Idc: "+692",
      Code: "MH",
    },
    {
      Name: "Martinique",
      Idc: "+596",
      Code: "MQ",
    },
    {
      Name: "Mauritania",
      Idc: "+222",
      Code: "MR",
    },
    {
      Name: "Mauritius",
      Idc: "+230",
      Code: "MU",
    },
    {
      Name: "Mayotte",
      Idc: "+262",
      Code: "YT",
    },
    {
      Name: "Mexico",
      Idc: "+52",
      Code: "MX",
    },
    {
      Name: "Micronesia, Federated States of Micronesia",
      Idc: "+691",
      Code: "FM",
    },
    {
      Name: "Moldova",
      Idc: "+373",
      Code: "MD",
    },
    {
      Name: "Monaco",
      Idc: "+377",
      Code: "MC",
    },
    {
      Name: "Mongolia",
      Idc: "+976",
      Code: "MN",
    },
    {
      Name: "Montenegro",
      Idc: "+382",
      Code: "ME",
    },
    {
      Name: "Montserrat",
      Idc: "+1664",
      Code: "MS",
    },
    {
      Name: "Morocco",
      Idc: "+212",
      Code: "MA",
    },
    {
      Name: "Mozambique",
      Idc: "+258",
      Code: "MZ",
    },
    {
      Name: "Myanmar",
      Idc: "+95",
      Code: "MM",
    },
    {
      Name: "Namibia",
      Idc: "+264",
      Code: "NA",
    },
    {
      Name: "Nauru",
      Idc: "+674",
      Code: "NR",
    },
    {
      Name: "Nepal",
      Idc: "+977",
      Code: "NP",
    },
    {
      Name: "Netherlands",
      Idc: "+31",
      Code: "NL",
    },
    {
      Name: "Netherlands Antilles",
      Idc: "+599",
      Code: "AN",
    },
    {
      Name: "New Caledonia",
      Idc: "+687",
      Code: "NC",
    },
    {
      Name: "New Zealand",
      Idc: "+64",
      Code: "NZ",
    },
    {
      Name: "Nicaragua",
      Idc: "+505",
      Code: "NI",
    },
    {
      Name: "Niger",
      Idc: "+227",
      Code: "NE",
    },
    {
      Name: "Nigeria",
      Idc: "+234",
      Code: "NG",
    },
    {
      Name: "Niue",
      Idc: "+683",
      Code: "NU",
    },
    {
      Name: "Norfolk Island",
      Idc: "+672",
      Code: "NF",
    },
    {
      Name: "Northern Mariana Islands",
      Idc: "+1670",
      Code: "MP",
    },
    {
      Name: "Norway",
      Idc: "+47",
      Code: "NO",
    },
    {
      Name: "Oman",
      Idc: "+968",
      Code: "OM",
    },
    {
      Name: "Pakistan",
      Idc: "+92",
      Code: "PK",
    },
    {
      Name: "Palau",
      Idc: "+680",
      Code: "PW",
    },
    {
      Name: "Palestinian Territory, Occupied",
      Idc: "+970",
      Code: "PS",
    },
    {
      Name: "Panama",
      Idc: "+507",
      Code: "PA",
    },
    {
      Name: "Papua New Guinea",
      Idc: "+675",
      Code: "PG",
    },
    {
      Name: "Paraguay",
      Idc: "+595",
      Code: "PY",
    },
    {
      Name: "Peru",
      Idc: "+51",
      Code: "PE",
    },
    {
      Name: "Philippines",
      Idc: "+63",
      Code: "PH",
    },
    {
      Name: "Pitcairn",
      Idc: "+872",
      Code: "PN",
    },
    {
      Name: "Poland",
      Idc: "+48",
      Code: "PL",
    },
    {
      Name: "Portugal",
      Idc: "+351",
      Code: "PT",
    },
    {
      Name: "Puerto Rico",
      Idc: "+1939",
      Code: "PR",
    },
    {
      Name: "Qatar",
      Idc: "+974",
      Code: "QA",
    },
    {
      Name: "Romania",
      Idc: "+40",
      Code: "RO",
    },
    {
      Name: "Russia",
      Idc: "+7",
      Code: "RU",
    },
    {
      Name: "Rwanda",
      Idc: "+250",
      Code: "RW",
    },
    {
      Name: "Reunion",
      Idc: "+262",
      Code: "RE",
    },
    {
      Name: "Saint Barthelemy",
      Idc: "+590",
      Code: "BL",
    },
    {
      Name: "Saint Helena, Ascension and Tristan Da Cunha",
      Idc: "+290",
      Code: "SH",
    },
    {
      Name: "Saint Kitts and Nevis",
      Idc: "+1869",
      Code: "KN",
    },
    {
      Name: "Saint Lucia",
      Idc: "+1758",
      Code: "LC",
    },
    {
      Name: "Saint Martin",
      Idc: "+590",
      Code: "MF",
    },
    {
      Name: "Saint Pierre and Miquelon",
      Idc: "+508",
      Code: "PM",
    },
    {
      Name: "Saint Vincent and the Grenadines",
      Idc: "+1784",
      Code: "VC",
    },
    {
      Name: "Samoa",
      Idc: "+685",
      Code: "WS",
    },
    {
      Name: "San Marino",
      Idc: "+378",
      Code: "SM",
    },
    {
      Name: "Sao Tome and Principe",
      Idc: "+239",
      Code: "ST",
    },
    {
      Name: "Saudi Arabia",
      Idc: "+966",
      Code: "SA",
    },
    {
      Name: "Senegal",
      Idc: "+221",
      Code: "SN",
    },
    {
      Name: "Serbia",
      Idc: "+381",
      Code: "RS",
    },
    {
      Name: "Seychelles",
      Idc: "+248",
      Code: "SC",
    },
    {
      Name: "Sierra Leone",
      Idc: "+232",
      Code: "SL",
    },
    {
      Name: "Singapore",
      Idc: "+65",
      Code: "SG",
    },
    {
      Name: "Slovakia",
      Idc: "+421",
      Code: "SK",
    },
    {
      Name: "Slovenia",
      Idc: "+386",
      Code: "SI",
    },
    {
      Name: "Solomon Islands",
      Idc: "+677",
      Code: "SB",
    },
    {
      Name: "Somalia",
      Idc: "+252",
      Code: "SO",
    },
    {
      Name: "South Africa",
      Idc: "+27",
      Code: "ZA",
    },
    {
      Name: "South Sudan",
      Idc: "+211",
      Code: "SS",
    },
    {
      Name: "South Georgia and the South Sandwich Islands",
      Idc: "+500",
      Code: "GS",
    },
    {
      Name: "Spain",
      Idc: "+34",
      Code: "ES",
    },
    {
      Name: "Sri Lanka",
      Idc: "+94",
      Code: "LK",
    },
    {
      Name: "Sudan",
      Idc: "+249",
      Code: "SD",
    },
    {
      Name: "SuriName",
      Idc: "+597",
      Code: "SR",
    },
    {
      Name: "Svalbard and Jan Mayen",
      Idc: "+47",
      Code: "SJ",
    },
    {
      Name: "Swaziland",
      Idc: "+268",
      Code: "SZ",
    },
    {
      Name: "Sweden",
      Idc: "+46",
      Code: "SE",
    },
    {
      Name: "Switzerland",
      Idc: "+41",
      Code: "CH",
    },
    {
      Name: "Syrian Arab Republic",
      Idc: "+963",
      Code: "SY",
    },
    {
      Name: "Taiwan",
      Idc: "+886",
      Code: "TW",
    },
    {
      Name: "Tajikistan",
      Idc: "+992",
      Code: "TJ",
    },
    {
      Name: "Tanzania, United Republic of Tanzania",
      Idc: "+255",
      Code: "TZ",
    },
    {
      Name: "Thailand",
      Idc: "+66",
      Code: "TH",
    },
    {
      Name: "Timor-Leste",
      Idc: "+670",
      Code: "TL",
    },
    {
      Name: "Togo",
      Idc: "+228",
      Code: "TG",
    },
    {
      Name: "Tokelau",
      Idc: "+690",
      Code: "TK",
    },
    {
      Name: "Tonga",
      Idc: "+676",
      Code: "TO",
    },
    {
      Name: "Trinidad and Tobago",
      Idc: "+1868",
      Code: "TT",
    },
    {
      Name: "Tunisia",
      Idc: "+216",
      Code: "TN",
    },
    {
      Name: "Turkey",
      Idc: "+90",
      Code: "TR",
    },
    {
      Name: "Turkmenistan",
      Idc: "+993",
      Code: "TM",
    },
    {
      Name: "Turks and Caicos Islands",
      Idc: "+1649",
      Code: "TC",
    },
    {
      Name: "Tuvalu",
      Idc: "+688",
      Code: "TV",
    },
    {
      Name: "Uganda",
      Idc: "+256",
      Code: "UG",
    },
    {
      Name: "Ukraine",
      Idc: "+380",
      Code: "UA",
    },
    {
      Name: "United Arab Emirates",
      Idc: "+971",
      Code: "AE",
    },
    {
      Name: "United Kingdom",
      Idc: "+44",
      Code: "GB",
    },
    {
      Name: "United States",
      Idc: "+1",
      Code: "US",
    },
    {
      Name: "Uruguay",
      Idc: "+598",
      Code: "UY",
    },
    {
      Name: "Uzbekistan",
      Idc: "+998",
      Code: "UZ",
    },
    {
      Name: "Vanuatu",
      Idc: "+678",
      Code: "VU",
    },
    {
      Name: "Venezuela, Bolivarian Republic of Venezuela",
      Idc: "+58",
      Code: "VE",
    },
    {
      Name: "Vietnam",
      Idc: "+84",
      Code: "VN",
    },
    {
      Name: "Virgin Islands, British",
      Idc: "+1284",
      Code: "VG",
    },
    {
      Name: "Virgin Islands, U.S.",
      Idc: "+1340",
      Code: "VI",
    },
    {
      Name: "Wallis and Futuna",
      Idc: "+681",
      Code: "WF",
    },
    {
      Name: "Yemen",
      Idc: "+967",
      Code: "YE",
    },
    {
      Name: "Zambia",
      Idc: "+260",
      Code: "ZM",
    },
    {
      Name: "Zimbabwe",
      Idc: "+263",
      Code: "ZW",
    },
  ],
};

// for (let i = 0; i < Country.Country.length; i++) {
//   console.log(`https://flagcdn.com/w320/${Country.Country[i].Code.toLowerCase()}.png`)
// }

export default Country;
