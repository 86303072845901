// src/Login.js
import React from 'react';
import { ApiPostNoAuth, ApiGet } from '../helpers/API/ApiData';
import * as authUtil from "@/utils/auth.util";
import * as userUtil from "@/utils/user.util";
import { toast } from 'react-toastify';
import { GoogleLogin } from '@react-oauth/google';

const GoogleLoginCom = ({
  ...rest
}) => {
  const handleLoginSuccess = async (credentialResponse) => {
    try {
      const response = await ApiPostNoAuth("signupwithgoogle/signup", { token: credentialResponse.credential, role: 'user' });
      // authUtil.setToken(credentialResponse.credential);
      const googleUserData = response.data;
      if (googleUserData) {
        authUtil.setToken(googleUserData.token);
        userUtil.setUserInfo({user: googleUserData.user});
        window.location.reload();
      }
    } catch (error) {
      toast.error('🔴 Google Sign-Up went wrong!' + error);
    }
  };

  const handleLoginError = (error) => {
    toast.error('🔴 Google Sign-Up went wrong!');
  };

  return (
    <span {...rest}>
      <GoogleLogin
        onSuccess={handleLoginSuccess}
        onError={handleLoginError}
      />
    </span>
  );
};

export default GoogleLoginCom;
