/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */
import React, { useEffect, useState } from "react";
import Header from "../Layout/Header";
import Sidebar from "../Layout/Sidebar";
import { ApiGet } from "../../helpers/API/ApiData";
import renderHTML from "react-render-html";
import FootTerms from "@/commonComs/FootTerms";

export default function Questions() {
  const [loading, setLoading] = useState(false);
  const [FaqData, setFaqData] = useState();

  ////////////////////////////////////// Terms and conditions  ////////////////////////////////////

  useEffect(() => {
    const getAllFaq = async () => {
      setLoading(true);
      await ApiGet("faq/get-all-faq")
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data.data);
            setFaqData(res.data.data);
            setLoading(true);
          } else {
            console.log("error");
          }
        })
        .catch((err) => {
          setLoading(true);
          console.log(err);
        });
    };
    getAllFaq();
  }, []);

  //////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div>
      <Header />
      <div className="flex">
        <>
          <Sidebar />
        </>
        <main className="s-layout__content">
          {FaqData ? (
            FaqData?.slice(0)
              ?.reverse()
              .map((data, key) => {
                return (
                  <>
                    <div className="pt-24 pb-6">
                      <div className="pl-10 pr-10">
                        <h2 className="adobe-font font-size-25 green-text-color tracking-normal pb-2">
                          Frequently asked Questions
                        </h2>
                      </div>
                    </div>
                    {data?.content.map((content, key) => {
                      return (
                        <div className="">
                          <div className="pl-10 pr-10">
                            <p className="font-size-18 white-text-color font-medium tracking-normal text-left pb-2">
                              Q. {content?.title}
                            </p>
                            <p className="font-size-18 white-text-color font-medium tracking-normal text-left pb-8">
                              {renderHTML(content?.description)
                                ? renderHTML(content?.description)
                                : ""}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </>
                );
              })
          ) : loading ? (
            <center>
              <div className="mt-36">
                <div className="dots">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>
            </center>
          ) : (
            <></>
          )}
        </main>
      </div>
      <FootTerms />
    </div>
  );
}
