/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */

import React from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import "../Audio/Audio.scss";
import { NavLink, useNavigate } from "react-router-dom";





export default function Audio() {
  const history = useNavigate();

  return (
    <div>
      <>
        <Header />
      </>
      <div className="flex">
        <>
          <Sidebar />
        </>
        <main className="s-layout__content">
          <div className="main-box">
            <div className="container-fluid">
              <div className="mixer-banner relative flex justify-center items-center">
                <h1 className="font-size-35 z-index-1 white-text-color font-bold cursor-pointer tracking-normal text-center mb-0">
                  Audio / Music Engineering
                </h1>
              </div>
            </div>
            <section className="pt-5">
              <div className="container-fluid">
                <div className="md:flex pt-24">
                  <div className="md:w-full">
                    <h1 className="font-size-30 white-text-color cursor-pointer font-medium tracking-normal text-center mb-0">
                      AUDIO MIXING
                    </h1>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="container-fluid pt-5">
                <div className="md:flex md:flex-wrap">
                  <div className="md:w-1/4 pl-3 pr-3 mb-5">
                    <div className="mixer-box">
                      <img
                        src={
                          require("../../../Assets/Images/mixer1.jpg").default
                        } alt=""
                      />
                      <div className="mixer-text">
                        <p className="font-size-40 white-text-color font-bold tracking-nromal mb-0">
                          Upcoming <br /> Artist
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="md:w-1/4 pl-3 pr-3 mb-5">
                    <NavLink to="/mixierdetails" onClick={(e) => {
                      e.preventDefault();
                      history(-1)
                    }}>
                      <div className="mixer-box">
                        <img
                          src={
                            require("../../../Assets/Images/mixer2.jpg").default
                          } alt=""
                        />
                        <div className="mixer-text">
                          <p className="font-size-40 white-text-color font-bold tracking-nromal mb-0">
                            Established <br />
                            Artist
                          </p>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                  <div className="md:w-1/4 pl-3 pr-3 mb-5">
                    <div className="mixer-box">
                      <img
                        src={
                          require("../../../Assets/Images/mixer3.jpg").default
                        }alt=""
                      />
                      <div className="mixer-text">
                        <p className="font-size-40 white-text-color font-bold tracking-nromal mb-0">
                          professional <br />
                          Artist
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="md:w-1/4 pl-3 pr-3 mb-5">
                    <div className="mixer-box">
                      <img
                        src={
                          require("../../../Assets/Images/mixer1.jpg").default
                        }alt=""
                      />
                      <div className="mixer-text">
                        <p className="font-size-40 white-text-color font-bold tracking-nromal mb-0">
                          Upcoming <br /> Artist
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="md:w-1/4 pl-3 pr-3 mb-5">
                    <div className="mixer-box">
                      <img
                        src={
                          require("../../../Assets/Images/mixer2.jpg").default
                        }alt=""
                      />
                      <div className="mixer-text">
                        <p className="font-size-40 white-text-color font-bold tracking-nromal mb-0">
                          Established <br />
                          Artist
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="md:w-1/4 pl-3 pr-3 mb-5">
                    <div className="mixer-box">
                      <img
                        src={
                          require("../../../Assets/Images/mixer3.jpg").default
                        }alt=""
                      />
                      <div className="mixer-text">
                        <p className="font-size-40 white-text-color font-bold tracking-nromal mb-0">
                          professional <br />
                          Artist
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="md:w-1/4 pl-3 pr-3 mb-5">
                    <div className="mixer-box">
                      <img
                        src={
                          require("../../../Assets/Images/mixer2.jpg").default
                        }alt=""
                      />
                      <div className="mixer-text">
                        <p className="font-size-40 white-text-color font-bold tracking-nromal mb-0">
                          Established <br />
                          Artist
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="md:w-1/4 pl-3 pr-3 mb-5">
                    <div className="mixer-box">
                      <img
                        src={
                          require("../../../Assets/Images/mixer3.jpg").default
                        }alt=""
                      />
                      <div className="mixer-text">
                        <p className="font-size-40 white-text-color font-bold tracking-nromal mb-0">
                          professional <br />
                          Artist
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </div>
  );
}
