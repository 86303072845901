import { NEW_PROFILE_PICTURE } from "./actionType";
const initialStore = {};

const ProfilePicture = (state = initialStore, action) => {
  switch (action.type) {
    case NEW_PROFILE_PICTURE:
      return {...action.payload};
    default:
      return state;
  }
};

export default ProfilePicture;
