/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */

import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ApiGet } from "../../../helpers/API/ApiData";
import "../../js/Artist/artist.scss";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import Sidebar from "../../Layout/Sidebar";
import { useSelector, useDispatch } from "react-redux";
import { getArtist } from "../../../store/reducers/Artists/action";
import { getMusicData } from "../../../store/reducers/MusicData/action";
import * as authUtil from "../../../utils/auth.util";
import avatar from "@/Assets/Images/profile.png";

const Artist = (props) => {
  const allArtists = useSelector((state) => state.AllArtist);
  const dispatch = useDispatch();
  const history = useNavigate();
  const [loginModal, setLoginModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (allArtists?.length === 0 || 6) {
      getAllArtists();
    }
  }, []);

  const goToArtistPage = (id) => {
    history(`/profile/${id}`);
  }

  const getAllArtists = async () => {
    await ApiGet(`artist/get-all-artists/${null}`)
      .then((res) => {
        if (res.status === 200) {
          dispatch(getArtist(res.data.data));
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <>
        <Header />
      </>
      <div className="flex">
        <>
          <Sidebar />
        </>
        <main className="s-layout__content">
          <div className="main-box">
            <div className="container-fluid">
              <div className="md:flex pt-24  pt-24-mobile">
                <div className="md:w-full">
                  <div className="pt-5 flex items-center pt-5-mobile">
                    <p onClick={(e) => {
                      e.preventDefault();
                      history(-1)
                    }}
                      onTouchStart={(e) => {
                        e.preventDefault();
                        history(-1)
                      }}>
                      <i className="fas fa-arrow-left cursor-pointer icon-font-size text-white"></i>
                    </p>
                    <p className="pl-3 font-size-35 white-text-color font-medium tracking-normal text-center mb-0">
                      Artists You May Like
                    </p>
                  </div>
                </div>
              </div>
              {!allArtists?.length && (
                <div className="dots mt-10">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              )}
              <div className="cus-grid-one pt-15">
                {allArtists?.length
                  ? allArtists
                    ?.slice(0, 150)
                    ?.map((rec, index) => {
                      return (
                        <div className="grid-items" key={index}>
                          <div
                            className="artist-profile-main"
                            onClick={() => goToArtistPage(rec._id)}
                            OnTouchStart={() => goToArtistPage(rec._id)}>
                            <div className="artist-profile1 flex justify-center">
                              <img
                                src={
                                  rec.profile
                                    ? rec.profile
                                    : avatar
                                } alt=""
                              />
                            </div>
                            <div>
                              <p className="font-size-20 white-text-color font-medium tracking-normal text-center pt-4 mb-0 cursor-pointer">
                                {rec.artistName}
                                {/* {rec.realName}{" "} */}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
export default Artist;
