/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */

import React, { useEffect, useState } from "react";
import "../../js/Trending/trending.scss";
import "../../../Components/styles/dashboard.scss";
import Sidebar from "../../Layout/Sidebar";
import Header from "../../Layout/Header";
import { NavLink, useNavigate } from "react-router-dom";
import { ApiGet } from "../../../helpers/API/ApiData";
import { useSelector, useDispatch } from "react-redux";
import { getGenre } from "../../../store/reducers/AllGenre/action";
import LoadingBalls from "../../../Assets/LoadingBalls";
import { getMusicData } from "../../../store/reducers/MusicData/action";
import * as authUtil from "../../../utils/auth.util";



const MusicByGenre = (props) => {
  const [loading, setLoading] = useState(false);
  const allGenre = useSelector((state) => state.AllGenre);
  const dispatch = useDispatch();
  const history = useNavigate();
  const [loginModal, setLoginModal] = useState(false);
  const [showModal, setShowModal] = useState({
    open: false,
    musicId: null,
    amount: null,
    musicTitle: null,
    artistId: null,
  });



  useEffect(() => {
    if (allGenre?.length == 0) {
      getAllMusicMood();
    }
  }, []);

  const getAllMusicMood = async () => {
    setLoading(true);
    await ApiGet(`genre/get-all-genre/${null}`)
      .then((res) => {
        setLoading(false);
        if (res.status.data !== "Record list is empty") {
          dispatch(getGenre(res.data.data));
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  // play all free songs on a list one by one from page to page 
  const handlePlayallGenre = (record, key) => {
    if (record.isPaid) {
       if (authUtil.isLoggedIn()) {
        setShowModal({
          musicId: record._id,
          open: true,
          amount: record.amount,
          musicTitle: record.title,
          artistId: record.artistData?._id,
        });
       } else {
        setLoginModal(true);
       }
    } else {
      dispatch(getMusicData({playlistData: allGenre, index: key}));
    }
  };

  return (
    <div>
      <>
        <Header />
      </>
      <div className="flex">
        <>
          <Sidebar />
        </>
        <main className="s-layout__content">
          <div className="main-box">
            <div className="container-fluid">
              <div className="md:flex  pt-24  pt-24-mobile">
                <div className="md:w-full">
                  <div className="pt-5 flex items-center pt-5-mobile">
                    <NavLink to="/" onClick={(e) => {
                      e.preventDefault();
                      history(-1)
                    }}
                    onTouchStart={(e) => {
                      e.preventDefault();
                      history(-1)
                    }}
                    >
                      <p>
                        <i className="fas fa-arrow-left cursor-pointer icon-font-size text-white"></i>
                      </p>
                    </NavLink>
                    <p className="pl-3 font-size-35 white-text-color font-medium tracking-normal text-center mb-0">
                      Music By Genre
                    </p>
                  </div>
                </div>
              </div>
              {!allGenre?.length && (
                <div className="dots mt-10">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              )}
              <div className="cus-grid pt-4">
                {allGenre && allGenre.length
                  ? allGenre?.map((record, key) => {
                      return (
                        <div className="grid-items">
                          <NavLink to={`/music/${record?.genre?._id}`}>
                            <div className="singer-box">
                              <div className="singer-img"
                                   onClick={() => 
                                    handlePlayallGenre(record, key) }        // play all free songs on a list one by one from page to page        
                                               
                                   OnTouchStart={() => 
                                     handlePlayallGenre(record, key)}
                              >

                                <img
                                  src={record?.genre?.genreImage}
                                  alt={record?.genre?.genreTitle}
                                />
                              </div>
                              <div className="singer-text p-5 mini-view-p-remove ">
                                {/* <NavLink to={`/music/${record._id}`}> */}
                                <div className="flex items-center justify-between cursor-pointer">
                                  <div>
                                    <p className="font-size-18 white-text-color font-normal tracking-normal mb-0">
                                      {record?.genre?.genreTitle}{" "}
                                    </p>
                                  </div>
                                </div>
                                {/* </NavLink> */}
                              </div>
                            </div>
                          </NavLink>
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
        </main>
        {loading && <LoadingBalls />}
      </div>
    </div>
  );
};

export default MusicByGenre;
