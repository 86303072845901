/**
 * verify data object
 * keys: a array that cantains fields you need to verify on data
 * data: object that need to verify
*/
export async function checkParamsValidate(keys = [], data) {
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const item = data[key];
    const type = Object.prototype.toString.call(item);
    if (type === "[object Array]" && item.length <= 0) {
      throw Error(`${key} value is empty`);
    } else if (type === "[object Object]" && Object.keys(item).length <= 0) {
      throw Error(`${key} value is empty`);
    } else {
      if (!item) throw Error(`${key} value is empty`);
    }
  }
}


/**
 * @param { Promise } promise
 * @param { Object= } errorExt - Additional Information you can pass to the err object
 * @return { Promise }
 */
export function catchError(
  promise,
  errorExt
) {
  return promise
    .then((data) => [null, data])
    .catch((err) => {
      if (errorExt) {
        Object.assign(err, errorExt);
      }
      return [err, undefined];
    });
}

