/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */

import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  ApiGet,
  ApiPost,
  ApiPut,
  ApiDelete,
} from "../../../helpers/API/ApiData";
import Header from "../../Layout/Header";
import Sidebar from "../../Layout/Sidebar";
import { Dropdown } from "react-bootstrap";
import { getMyPlaylist } from "../../../store/reducers/AllMyPlaylist/action";
import "../Profile/profile.scss";
import { Tooltip } from "@material-ui/core";
import { getMusicData } from "../../../store/reducers/MusicData/action";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";
import Menu from "../../../Assets/Images/dots.png";

import LocationOnIcon from "@material-ui/icons/LocationOn";
import { getProfilePicture } from "../../../store/reducers/UserProfile/action";
import { useSelector, useDispatch } from "react-redux";
import * as authUtil from "../../../utils/auth.util";
import * as userUtil from "../../../utils/user.util";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import * as userUtils from "../../../utils/user.util";
import useOnClickOutside from "../../../helpers/outsideClick";
import avatar from "@/Assets/Images/profile.png";

export default function UserProfile() {
  const userId = userUtils.getUserInfo()?.user?.id;
  const [userData, setUserData] = useState();
  const [userFirstName, setFirstName] = useState();
  const [userEmail, setEmail] = useState();
  const [userPlaylist, setUserPlaylist] = useState([]);
  const allPlaylist = useSelector((state) => state.AllPlaylist);
  const [loading, setLoading] = useState(false);
  const [userContact, setContact] = useState();
  const [playlistModal, setPlaylistModal] = useState({
    open: false,
    musicId: null,
  });
  const [userLastName, setLastName] = useState();
  const user = userUtil.getUserInfo();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalEditProfile, setShowModalEditProfile] = useState(false);
  const [dataChange, setDataChange] = useState([]);
  const [profileImage, setProfileImage] = useState();
  const [previewImage, setPreviewImage] = useState();
  const [errorfirstname, seterrorfirstname] = useState("");
  const [dId, setDownloadId] = useState();

  const [errorEmail, seterrorEmail] = useState("");
  const [errorvalidEmail, seterrorvalidEmail] = useState("");
  const [errorlastname, seterrorlastname] = useState("");
  const [errorContact, seterrorContact] = useState("");
  const [errorvalidContact, seterrorvalidContact] = useState("");

  const [removeImage, setRemoveImage] = useState([avatar]);

  const profilePicture = useSelector((state) => state.ProfilePicture);

  const removeUrl = (e) => {
    setProfileImage(e.target.files);
    setPreviewImage(URL.revokeObjectURL(e.target.files));
  };

  const dispatch = useDispatch();
  useEffect(() => {
    getByUserPlaylist();
  }, []);

  const modeloff = () => {
    setShowModalEditProfile(false);
    seterrorfirstname("");
    seterrorEmail("");
    seterrorlastname("");
    seterrorContact("");
    seterrorvalidEmail("");
  };

  useEffect(() => {
    console.log(userContact, "userContact");
  }, [userContact]);

  useEffect(() => {
    getUserData();
  }, [dataChange]);
  const getUserData = async () => {
    await ApiGet("users/get-my-profile")
      .then((res) => {
        console.log("User Data", res.data.data);
        console.log("user's id", res.data.data._id);
        dispatch(getProfilePicture(res.data.data));
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getByUserPlaylist = async () => {
    await ApiGet("playlist/get-by-user")
      .then((res) => {
        if (res.status.data !== "Record list is empty") {
          setUserPlaylist(res.data.data);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const shareLink = (id) => {
    navigator.clipboard.writeText(`${window.location.href}song/${id}`);
    toast.success("Link Copied..");
  };

  const [copied, setCopied] = useState(false);

  function copy() {
    const el = document.createElement("input");
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  }

  const handleAddToPlaylist = async (id) => {
    const data = {
      playlistId: id,
      musicId: playlistModal.musicId,
    };
    setLoading(true);
    await ApiPost("playlist/add-to-playlist", data)
      .then((res) => {
        setLoading(false);
        if (res.data.message.includes("exists")) {
          toast.error("Song is already added to the playlist!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            closeOnTouchStart: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          setPlaylistModal({ open: false });
          toast.success("Song is added to the playlist!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            closeOnTouchStart: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  const handleUpdateProfile = async () => {
    if (
      userFirstName === "" &&
      userLastName === "" &&
      userEmail === "" &&
      userContact === ""
    ) {
      seterrorfirstname("Please Enter the Firstname");
      seterrorEmail("Please Enter the Email");
      seterrorlastname("Please Enter the Lastname");
      seterrorContact("Please Enter the phonenumber");
    } else if (userFirstName === "" && userLastName === "") {
      seterrorfirstname("Please Enter the Firstname");
      seterrorlastname("Please Enter the Lastname");
    } else if (userEmail === "" && userContact === "") {
      seterrorEmail("Please Enter the Email");
      seterrorContact("Please Enter the phonenumber");
    } else if (userFirstName === "" && userEmail === "") {
      seterrorfirstname("Please Enter the Firstname");
      seterrorEmail("Please Enter the Email");
    } else if (userLastName === "" && userContact === "") {
      seterrorlastname("Please Enter the Lastname");
      seterrorContact("Please Enter the phonenumber");
    } else if (userFirstName === "" && userContact === "") {
      seterrorfirstname("Please Enter the Firstname");
      seterrorContact("Please Enter the phonenumber");
    } else if (
      userFirstName === "" &&
      userLastName === "" &&
      userEmail === ""
    ) {
      seterrorfirstname("Please Enter the Firstname");
      seterrorlastname("Please Enter the Lastname");
      seterrorEmail("Please Enter the Email");
    } else if (userLastName === "" && userEmail === "" && userContact === "") {
      seterrorlastname("Please Enter the Lastname");
      seterrorEmail("Please Enter the Email");
      seterrorContact("Please Enter the phonenumber");
    } else if (userEmail === "" && userContact === "" && userFirstName === "") {
      seterrorEmail("Please Enter the Email");
      seterrorContact("Please Enter the phonenumber");
      seterrorfirstname("Please Enter the Firstname");
    } else if (
      userFirstName === "" &&
      userLastName === "" &&
      userContact === ""
    ) {
      seterrorContact("Please Enter the phonenumber");
      seterrorfirstname("Please Enter the Firstname");
      seterrorlastname("Please Enter the Lastname");
    } else if (userFirstName === "") {
      console.log("userFistname");
      seterrorfirstname("Please Enter the Firstname");
    } else if (userEmail === "") {
      console.log("email");
      seterrorEmail("Please Enter the Email");
      seterrorvalidEmail("");
    } else if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        userEmail
      ) &
      (userEmail !== undefined)
    ) {
      seterrorvalidEmail("Please Enter valid Email");
    } else if (userLastName === "") {
      console.log("lastname");
      seterrorlastname("Please Enter the Lastname");
    } else if (userContact === "") {
      console.log("userContact");
      seterrorContact("Please Enter the phonenumber");
      seterrorvalidContact("");
    } else if (!/^[0-9]{10}$/.test(userContact) && userContact !== undefined) {
      console.log(userContact, "userContact");
      seterrorvalidEmail("");
      seterrorvalidContact("Please Enter the valid phonenumber");
    } else {
      const proflieData = new FormData();
      proflieData.append(
        "firstName",
        userFirstName ? userFirstName : profilePicture.firstName
      );
      proflieData.append("email", userEmail ? userEmail : profilePicture.email);
      proflieData.append(
        "contact",
        userContact ? userContact : profilePicture.contact
      );
      proflieData.append("profile", profileImage);
      proflieData.append(
        "lastName",
        userLastName ? userLastName : profilePicture.lastName
      );

      console.log(proflieData);
      await ApiPut("users/Update-profile", proflieData, {
        "Content-Type": "multipart/form-data",
      })
        .then((res) => {
          console.log(res);
          setContact(res.data.data.contact);
          setEmail(res.data.data.email);
          setFirstName(res.data.data.firstName);
          setLastName(res.data.data.lastName);
          setShowModalEditProfile(false);
          setDataChange([...dataChange, "update profile"]);
          seterrorlastname("");
          seterrorContact("");
          seterrorvalidEmail("");
          seterrorEmail("");
          seterrorfirstname("");
          seterrorvalidContact("");
        })
        .catch((err) => {
          seterrorlastname("");
          seterrorContact("");
          seterrorvalidEmail("");
          seterrorEmail("");
          seterrorfirstname("");
          seterrorvalidContact("");
          console.log(err);
        });
    }
  };

  const downloadMusic = async (id) => {
    console.log("Downloading");
    await ApiGet(`music/download?musicId=${id}`)
      .then((res) => {
        console.log("Downloads", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const history = useNavigate();
  const logoutUser = () => {
    authUtil.logout();
    history("/");
    window.location.reload();
  };

  const handleDeleteUser = async () => {
    await ApiDelete("users/delete-user")
      .then((res) => {
        setDataChange([...dataChange, "user delete"]);
        toast.success("User Deleted");
        logoutUser();
      })
      .catch((err) => {
        console.log("err");
      });
  };

  const handleSendEmail = async (id) => {
    const bodyId = {
      musicId: id,
      userId: userId,
    };
    await ApiPost(`downloadUser/downloadUsers`, bodyId)
      .then((res) => {
        console.log("Downloads", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handledownload = async (data) => {
    toast.success("Your download will begin shortly");
    let url = data.musicUrl;
    let title = data?.musicTitle ? data?.musicTitle : data?.title;
    setLoading(true);
    await axios({
      url: url,
      method: "GET",
      responseType: "blob",
      headers: {
        "Content-Type": "video/mpeg",
      },
    })
      .then(async (response) => {
        handleSendEmail(data._id);
        setLoading(false);
        console.log("object");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${title}.mp3`); //or any other extension
        document.body.appendChild(link);
        downloadUserList(data);

        await downloadMusic(data._id);
        link.click();
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  const downloadUserList = async (data) => {
    const body = {
      musicId: data._id,
      userId: userId,
      artistId: data.artistId._id,
    };

    await ApiPost(
      `musicDownloadListForArtist/create-musicDownloadListForArtist`,
      body
    )
      .then((res) => {
        console.log("");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const [open, setOpen] = useState({});
  const NavigatorRef2 = useRef();

  useOnClickOutside(NavigatorRef2, () => {
    setOpen({});
  });

  return (
    <div>
      <>
        <Header />
      </>
      <div className="flex">
        <>
          <Sidebar />
        </>
        <main className="s-layout__content">
          <div className="main-box">
            <div className="container-fluid">
              <div className="md:flex">
                <div className="md:w-full pt-5 flex items-center">
                  <NavLink to="/" onClick={(e) => {
                      e.preventDefault();
                      history(-1)
                    }}>
                    <p>
                      <i className="fas fa-arrow-left cursor-pointer icon-font-size text-white"></i>
                    </p>
                  </NavLink>
                  <p className="font-size-35 white-text-color font-medium pl-5 tracking-normal text-center mb-0">
                    User Profile
                  </p>
                </div>
              </div>
              <div className="lg:flex pt-5">
                <div className="lg:w-2/6 pr-3 pt-6 ">
                  <div className="md:flex">
                    <div className="md:w-full">
                      <div className="flex items-center flex-direction-mobile">
                        <div className="pr-4">
                          <div className="music-profile-img relative">
                            <div className="profile-image">
                              <img
                                className="img1"
                                src={
                                  profilePicture.profile
                                    ? profilePicture.profile
                                    : avatar
                                }
                                alt=""
                              />
                            </div>
                            
                            <div
                              className="profile-edit flex items-center justify-center cursor-pointer"
                              onClick={() => {
                                setShowModalEditProfile(true);
                              }}
                              OnTouchStart={() => {
                                setShowModalEditProfile(true);
                              }}
                            >
                              <i className="fas fa-pencil-alt white-text-color"></i>
                            </div>
                          </div>
                        </div>
                        <div className="pl-3">
                          <p className="font-size-30 white-text-color font-medium tracking-normal mb-0 cursor-pointer">
                            {profilePicture?.firstName}{" "}
                            {profilePicture?.lastName}
                          </p>
                          {/* <p className="font-size-18 white-text-color font-normal tracking-normal mb-0 mt-2 cursor-pointer">
                            Free Account
                          </p> */}
                        </div>
                      </div>
                      <br />
                      <div className="cursor-pointer profile-align-between">
                        <p className="font-size-15 white-text-color font-normal tracking-normal text-left">
                          <MailIcon />
                          <span className="font-bold pl-3">
                            {profilePicture?.email}
                          </span>
                        </p>
                        <p className="font-size-15 white-text-color font-normal tracking-normal text-left mt-4">
                          <PhoneIcon />
                          <span className="font-bold pl-3">
                            {profilePicture?.countryCode}{" "}
                            {profilePicture?.contact}
                          </span>
                        </p>

                        <p className="font-size-15 white-text-color font-normal tracking-normal text-left mt-4">
                          <LocationOnIcon />{" "}
                          <span className="font-bold pl-3">
                            {profilePicture?.country}
                          </span>
                        </p>
                        <div className="pt-3">
                          <button
                            className="delete-button"
                            onClick={() => {
                              setDeleteOpen(true);
                            }}
                            OnTouchStart={() => {
                              setDeleteOpen(true);
                            }}
                          >
                            Delete Account
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lg:w-4/6 pl-3 pr-3 m-pl-0 m-pr-0 mobile-bottom-align">
                  <div className="profile-show">
                    <div className="md:flex">
                      <div className="md:w-full lg:p-3 md:p-3">
                        <div className="flex items-center justify-between m-block">
                          <p className="font-size-23 white-text-color font-medium tracking-normal cursor-pointer">
                            Your purchased Music
                          </p>
                          <p className="font-size-16 tracking-normal font-normal white-text-color">
                            Your total music{" "}
                            {profilePicture?.musicPurchased?.length}
                          </p>
                        </div>
                        <div className="profile-bottom-border mt-3"></div>
                        <div className="md:flex md:flex-wrap pt-5">
                          {profilePicture?.musicPurchased?.length ? (
                            profilePicture.musicPurchased.map((rec, index) => {
                              return (
                                <div className="md:w-1/3 pl-3 pr-3 cursor-pointer">
                                  <div
                                    className="music-profile-img"
                                    onClick={() =>
                                      dispatch(
                                        getMusicData({
                                          musicUrl: rec.musicUrl,
                                          title: rec.musicTitle,
                                          artistData: {
                                            artistName: rec.artistId.artistName,
                                            realName: rec.artistId.realName,
                                          },
                                        })
                                      )
                                    }
                                    OnTouchStart={() =>
                                      dispatch(
                                        getMusicData({
                                          musicUrl: rec.musicUrl,
                                          title: rec.musicTitle,
                                          artistData: {
                                            artistName: rec.artistId.artistName,
                                            realName: rec.artistId.realName,
                                          },
                                        })
                                      )
                                    }
                                  >
                                    <img src={rec?.musicImage} alt=""/>
                                  </div>
                                  <div className="pt-3 flex justify-between items-center pr-0 p-4">
                                    <p className="font-size-18 tracking-normal white-text-color font-normal mb-0 cursor-pointer">
                                      {rec?.musicTitle}
                                    </p>
                                    <div className="">
                                      <div>
                                        <div
                                          className="menu_dd"
                                          ref={NavigatorRef2}
                                        >
                                          <div
                                            className="menu-img"
                                            onClick={() => {
                                              setOpen((pre) => {
                                                return {
                                                  ...pre,
                                                  [`newrel${rec._id}`]:
                                                    !pre[`newrel${rec._id}`],
                                                };
                                              });
                                            }}
                                          >
                                            <img
                                              src={Menu}
                                              style={{ height: "25px" }}
                                              alt=""
                                            />
                                          </div>
                                          {open[`newrel${rec._id}`] && (
                                            <div
                                              className="menu-box"
                                              // ref={NavigatorRef1}
                                            >
                                              <div
                                                className="text-white p-2 cursor-pointer"
                                                onClick={() =>
                                                  setPlaylistModal({
                                                    musicId: rec._id,
                                                    open: true,
                                                  })
                                                }
                                                OnTouchStart={() =>
                                                  setPlaylistModal({
                                                    musicId: rec._id,
                                                    open: true,
                                                  })
                                                }
                                              >
                                                Add to playlist
                                              </div>

                                              <div
                                                className="text-white p-2 cursor-pointer"
                                                onClick={() =>
                                                  dispatch(getMusicData(rec))
                                                }
                                                OnTouchStart={() =>
                                                  dispatch(getMusicData(rec))
                                                }
                                              >
                                                Play song now
                                              </div>

                                              <div
                                                className="text-white p-2 cursor-pointer"
                                                onClick={() =>
                                                  history(`/song/${rec._id}`)
                                                }
                                                OnTouchStart={() =>
                                                  history(`/song/${rec._id}`)
                                                }
                                              >
                                                Details
                                              </div>

                                              <div
                                                className="text-white p-2 cursor-pointer"
                                                onClick={() => {
                                                  setDownloadId(rec._id);
                                                  authUtil.isLoggedIn() ? (
                                                    handledownload(rec)
                                                  ) : (
                                                    <></>
                                                  );
                                                }}
                                                OnTouchStart={() => {
                                                  setDownloadId(rec._id);
                                                  authUtil.isLoggedIn() ? (
                                                    handledownload(rec)
                                                  ) : (
                                                    <></>
                                                  );
                                                }}
                                              >
                                                Download
                                              </div>
                                              <div
                                                className="text-white p-2 cursor-pointer"
                                                onClick={() => {
                                                  shareLink(rec._id);
                                                }}
                                                OnTouchStart={() => {
                                                  shareLink(rec._id);
                                                }}
                                              >
                                                <button onClick={copy} OnTouchStart={copy}>
                                                  {!copied
                                                    ? "Copy link"
                                                    : "Copy link"}
                                                </button>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <>
                              {" "}
                              <div className="text-center d-flex mt-4 text-white">
                                <h1> No Songs Purchased </h1>
                              </div>
                            </>

                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {deleteOpen ? (
                <div>
                  <div className="animation justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-full my-6 mx-auto lg:w-5/12 md:w-4/5 sm:w-1/2">
                      {/*content*/}
                      <div className="relative modal-login-box border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 rounded-t">
                          <h3 className="text-2xl font-bold text-white"> </h3>

                          <button
                            className="p-1 ml-auto bg-transparent border-0 text-white  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() => setDeleteOpen(false)}
                            OnTouchStart={() => setDeleteOpen(false)}

                          >
                            <p>
                              <i className="fas fa-times login-text-color relative"></i>
                            </p>
                          </button>
                        </div>
                        {/*body*/}

                        <div className="relative p-6 flex-auto end-text l-pt-0">
                          <div>
                            <div className="text-white text-center">
                              Arew you sure you want to delete Your account ?
                              <div>
                                <div className="login_logo pl-5 pr-5 flex justify-center relative pb-6">
                                  <img
                                    src={
                                      require("../../../Assets/Images/login-logo.png")
                                        .default
                                    }
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                            <p className="text-center font-size-14 pt-12">
                              <span
                                style={{
                                  color: "red",
                                }}
                              ></span>
                            </p>
                            <div className="flex flex-col items-left justify-center login-placeholder pt-5"></div>
                            <div className="flex items-center justify-center">
                              <div className="pr-2">
                                <div className="flex justify-center">
                                  <button
                                    className="bg-transparent delete-modal-btn font-size-16 white-text-color font-normal tracking-normal cursor-pointer"
                                    onClick={() => setDeleteOpen(false)}
                                    OnTouchStart={() => setDeleteOpen(false)}
                                    type="submit"
                                  >
                                    {" "}
                                    Cancel
                                  </button>
                                </div>
                              </div>
                              <div className="pl-2">
                                <div className="flex justify-center">
                                  <button
                                    className="login-modal-btn font-size-16 white-text-color font-normal tracking-normal cursor-pointer"
                                    onClick={() => handleDeleteUser()}
                                    OnTouchStart={() => handleDeleteUser()}
                                    type="submit"
                                  >
                                    {" "}
                                    Delete Account
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </div>
              ) : null}

              {playlistModal.open ? (
                <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                      {/*content*/}
                      <div className="playlist border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div
                          className="justify-content-center p-5 relative  border-solid border-white-200 rounded-t"
                          // style={{
                          //   backgroundColor: "#090C10",
                          // }}
                        >
                          <h3 className="text-2xl text-center font-bold text-white">
                            Select Playlist
                          </h3>

                          <button
                            className="p-1 ml-auto bg-transparent border-0 text-white  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }
                            OnTouchStart={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }
                          >
                            <p className="button-center-modal">
                              <i className="fas fa-times"></i>
                            </p>
                          </button>
                        </div>
                        {/*body*/}
                        <div
                          className="relative p-6 flex-auto"
                          // style={{
                          //   backgroundColor: "#090C10",
                          // }}
                        >
                          <p className="my-4 text-white text-lg leading-relaxed">
                            <div
                              className="flex items-center flex-row flex-wrap pt-4"
                              style={{
                                height: "450px",
                                overflowY: "overlay",
                              }}
                            >
                              {userPlaylist && userPlaylist.length ? (
                                userPlaylist.map((record, index) => {
                                  return (
                                    <div
                                      className="pl-3 pr-3 cursor-pointer "
                                      key={index}
                                      onClick={() => {
                                        handleAddToPlaylist(record._id);
                                      }}
                                      OnTouchStart={() => {
                                        handleAddToPlaylist(record._id);
                                      }}
                                    >
                                      <div className="card-holder">
                                        <div className="singer-box">
                                          <div className="singer-img">
                                            <img
                                              src={
                                                (record &&
                                                  record.Songs &&
                                                  record.Songs.length &&
                                                  record.Songs[0].musicImage) ||
                                                `https://source.unsplash.com/1600x900/?${record.playlist_name}`
                                              }
                                              style={{
                                                height: "150px",
                                                width: "335px",
                                              }}
                                              alt=""
                                            />
                                          </div>
                                          <div className="singer-text p-4">
                                            <div className="flex items-center justify-between">
                                              <div>
                                                <p className="font-size-14 white-text-color font-normal tracking-normal mb-0">
                                                  {record.playlist_name}
                                                </p>
                                              </div>
                                              <div>
                                                {record?.Songs?.map((rec) => {
                                                  if (
                                                    rec._id ===
                                                    playlistModal.musicId
                                                  ) {
                                                    return (
                                                      <>
                                                        <p className="cursor-pointer font-size-14 login-text-color">
                                                          added
                                                          <i className="pl-1 fas fa-check cursor-pointer font-size-14 login-text-color"></i>
                                                        </p>
                                                      </>
                                                    );
                                                  }
                                                })}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="justify-content-center widthFull">
                                  <h1 className="text-white text-2xl mt-10">
                                    Playlist Is Empty.
                                  </h1>
                                </div>
                              )}
                            </div>
                          </p>
                        </div>
                        {/*footer*/}
                        <div
                          className="flex items-center justify-end p-4  border-solid border-white  "
                          
                        >
                          <button
                            className="closebtn text-white border-solid border border-white font-bold uppercase px-6 py-2 text-sm  mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }
                            OnTouchStart={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
              ) : null}

              {showModalEditProfile ? (
                <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
                    <div className="relative w-auto my-6 mx-auto md:w-8/12">
                      {/*content*/}
                      <div className="md:w-8/12 relative modal-profile-box border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 rounded-t">
                          <h3 className="text-2xl font-bold text-white"> </h3>
                          <button
                            className="p-1 ml-auto bg-transparent border-0 mt-10 text-white  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() => modeloff()}
                            OnTouchStart={() => modeloff()}

                          >
                            <p>
                              <i className="fas fa-times login-text-color relative"></i>
                            </p>
                          </button>
                        </div>

                        {/*body*/}
                        <div className="">
                          <div className="mr-5">
                            {profilePicture?.profile ? (
                              <div className="profile-image pl-2">
                                <img
                                  width="120px"
                                  className="img1 m-auto"
                                  src={
                                    previewImage && previewImage
                                      ? previewImage
                                      : profilePicture?.profile
                                  }
                                  alt=""
                                />
                              </div>
                            ) : (
                              <div className="">
                                <img src={avatar} width="200px" height="200px" className="m-auto"></img>
                              </div>
                            )}
                          </div>
                          <div>
                            <div className="d-flex justify-content-start align-items-center mt-2">
                              {/* //* Guidelines Text */}

                              {/* <div className="profile-img-upload">
                                <div className="avatar-upload">
                                  <div className="avatar-edit">
                                    <input
                                      type="file"
                                      id="imageUpload"
                                      name="image-upload"
                                      accept=".png, .jpg, .jpeg"
                                      onChange={(e) => {
                                        setProfileImage(e.target.files[0]);
                                        setPreviewImage(
                                          URL.createObjectURL(e.target.files[0])
                                        );
                                      }}
                                    />
                                  </div>
                                  <label
                                    htmlFor="imageUpload"
                                    className="profile-text bold cursor-pointer"
                                  >
                                    Change Profile Picture
                                  </label>
                                </div>
                              </div> */}
                              <div className="profile-img-upload">
                            <div className="avatar-upload">
                          <div className="avatar-edit">
                        <input
                          type="file"
                          id="imageUpload"
                          name="image-upload"
                          accept=".png, .jpg, .jpeg"
                          onChange={(e) => {
                          setProfileImage(e.target.files[0]);
                          setPreviewImage(URL.createObjectURL(e.target.files[0]));
                               }}
                        />
                      </div>
                    <label htmlFor="imageUpload" className="profile-text bold cursor-pointer">
                      <p className="text-center mt-4 mb-4">Change Profile Picture</p>
                    </label>
                 </div>
                    {previewImage && (
                   <img
                     src={previewImage}
                      alt="Profile Preview"
                      className="profile-img"
                    />
                      )}
                 </div>
                  </div>
                    </div>
                      </div>
                        <div className=" relative p-6 flex-auto l-pt-0">
                          <div>
                            <div className="flex flex-col items-left justify-center login-placeholder pt-5">
                              <form>
                                <div className="">
                                  <span
                                    style={{
                                      color: "red",
                                      top: "0px",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {errorfirstname}
                                  </span>
                                  <input
                                    required
                                    type="text"
                                    name="firstName"
                                    defaultValue={
                                      profilePicture.firstName &&
                                      profilePicture.firstName
                                    }
                                    className="form-control-login mb-8"
                                    placeholder="Enter Name"
                                    onChange={(e) =>
                                      setFirstName(e.target.value)
                                    }
                                  />{" "}
                                  {/* <div className="errorlastname">{errorfirstname}</div> */}
                                </div>
                                <div className="">
                                  <span
                                    style={{
                                      color: "red",
                                      top: "0px",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {errorlastname}
                                  </span>
                                  <input
                                    required
                                    type="text"
                                    name="email"
                                    defaultValue={
                                      profilePicture.lastName &&
                                      profilePicture.lastName
                                    }
                                    className="form-control-login mb-8"
                                    placeholder="Enter lastName"
                                    onChange={(e) =>
                                      setLastName(e.target.value)
                                    }
                                  />
                                  {/* <div className="errorlastname">{errorlastname}</div> */}
                                </div>

                                <div className="">
                                  <span
                                    style={{
                                      color: "red",
                                      top: "0px",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {errorContact}
                                    {errorvalidContact}
                                  </span>
                                  <input
                                    required
                                    type="tel"
                                    name="contact"
                                    max="10"
                                    min="10"
                                    defaultValue={
                                      profilePicture.contact &&
                                      profilePicture.contact
                                    }
                                    className="form-control-login mb-8"
                                    placeholder="Enter contact"
                                    onChange={(e) => setContact(e.target.value)}
                                  />
                                  {/* <div className="errorlastname">{errorContact}</div> */}
                                </div>
                                <div className="">
                                  <span
                                    style={{
                                      color: "red",
                                      top: "0px",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {errorEmail}
                                    {errorvalidEmail}
                                  </span>
                                  <input
                                    required
                                    type="email"
                                    name="email"
                                    disabled
                                    defaultValue={
                                      profilePicture.email &&
                                      profilePicture.email
                                    }
                                    className="form-control-login mb-8"
                                    placeholder="Enter email"
                                    onChange={(e) => setEmail(e.target.value)}
                                  />{" "}
                                  {/* <div className="errorlastname">{errorEmail}</div> */}
                                </div>
                                
                              </form>
                            </div>
                            <div>
                              <div className="flex justify-center">
                                <button
                                  className="login-modal-btn font-size-16 white-text-color font-normal tracking-normal cursor-pointer"
                                  onClick={() => handleUpdateProfile()}
                                  OnTouchStart={() => handleUpdateProfile()}

                                  type="submit"
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
              ) : null}
              {showModal ? (
                <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                      {/*content*/}
                      <div className="poppop border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div
                          className="flex items-start justify-between pt-5 pr-5 pl-5 border-solid border-white-100 rounded-t-lg"
                          
                        >
                          <h3 className="text-2xl font-bold text-white">
                            Buy this song or album
                          </h3>
                          <button
                            className="p-1 ml-auto bg-transparent border-0 text-white  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() => setShowModal(false)}
                            OnTouchStart={() => setShowModal(false)}

                          >
                            <p>
                              <i className="fas fa-times"></i>
                            </p>
                          </button>
                        </div>
                        {/*body*/}
                        <div
                          className="relative p-6 flex-auto"
                        >
                          <p className="my-4 text-white text-lg leading-relaxed">
                            Purchase this song to listen & download free.
                          </p>
                        </div>
                        {/*footer*/}
                        <div
                          className="flex items-center justify-end p-4 border-t border-solid border-white rounded-b"
                        >
                          <button
                            className="text-red-500 border-solid border-white font-bold uppercase px-6 py-2 text-sm  mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() => setShowModal(false)}
                            OnTouchStart={() => setShowModal(false)}

                          >
                            Close
                          </button>
                          <button
                            className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() => setShowModal(false)}
                            OnTouchStart={() => setShowModal(false)}

                          >
                            Purchase
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
              ) : null}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
