/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { ApiPost, ApiGet } from "../../../helpers/API/ApiData";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import Sidebar from "../../Layout/Sidebar";
import "../Profile/profile.scss";
import * as authUtil from "../../../utils/auth.util";
import * as userUtil from "../../../utils/user.util";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Payments from "../Payments/Payments";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getMusicData } from "../../../store/reducers/MusicData/action";
import LoadingBalls from "../../../Assets/LoadingBalls";
import { Tooltip } from "@material-ui/core";
import Donate from "../Payments/donate";
import Menu from "../../../Assets/Images/dots.png";

import Country from "../countries/Country";
import * as userUtils from "../../../utils/user.util";
import Userlogin from "../Login/Login";
import UserSignup from "../Login/Signup";
import Forgotpassword from "../Login/Forgotpassword";
import { useRef } from "react";
import useOnClickOutside from "../../../helpers/outsideClick";
import avatar from "@/Assets/Images/profile.png";

const stripePromise = loadStripe(`${process.env.REACT_APP_PK_LIVE}`);

export default function Profile() {
  const userId = userUtils.getUserInfo()?.user?.id;

  const [user_id] = useState(userUtil?.getUserInfo()?.user?.id);
  const route = window.location.pathname;
  var artistId = route.split("/").slice(-1)[0];
  const [loading, setLoading] = useState(false);
  const [selectionBox, setSelectionBox] = useState(true);
  const [formBox, setFormBox] = useState(false);
  const [artistData, setArtistData] = useState();
  const [forgotModal, setForgotModal] = useState(false);

  const [Idc, setIdc] = useState();
  const [userPlaylist, setUserPlaylist] = useState([]);
  const [dId, setDownloadId] = useState();
  const [paymentModal, setPaymentModal] = useState({
    open: false,
    musicId: null,
    amount: null,
    musicTitle: null,
    artistId: null,
  });

  const downloadMusic = async (id) => {
    await ApiGet(`music/download?musicId=${id}`)
      .then((res) => {
        console.log("Downloads", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleSendEmail = async (id) => {
    const bodyId = {
      musicId: id,
      userId: userId,
    };
    await ApiPost(`downloadUser/downloadUsers`, bodyId)
      .then((res) => {
        console.log("Downloads", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handledownload = async (data) => {
    let url = data.musicUrl;
    let title = data?.musicTitle ? data?.musicTitle : data?.title;

    setLoading(true);
    await axios({
      url: url,
      method: "GET",
      responseType: "blob",
      headers: {
        "Content-Type": "video/mpeg",
      },
    })
      .then(async (response) => {
        toast.success("Your download will begin shortly");
        handleSendEmail(data._id);
        setLoading(false);
        console.log("object");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${title}.mp3`); //or any other extension
        document.body.appendChild(link);
        downloadUserList(data);
        await downloadMusic(data._id);
        console.log("dres", response);
        link.click();
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  const downloadUserList = async (data) => {
    console.log("datafff", data);
    const body = {
      musicId: data._id,
      userId: userId,
      artistId: artistId,
    };

    await ApiPost(
      `musicDownloadListForArtist/create-musicDownloadListForArtist`,
      body
    )
      .then((res) => {
        console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxx", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const [showModal, setShowModal] = useState({
    open: false,
    musicId: null,
    amount: null,
    musicTitle: null,
    artistId: null,
  });
  const [loginModal, setLoginModal] = useState(false);
  const [signupModal, setSignupModal] = useState(false);
  const [playlistModal, setPlaylistModal] = useState({
    open: false,
    musicId: null,
  });
  const [errors, setErrors] = useState([]);
  const [signupErrors, setSignupErrors] = useState([]);
  const [formData, setFormData] = useState({});
  const [signupFormData, setSignupFormData] = useState({});
  const [dataChange, setDataChange] = useState([]);
  const [countryInit, setCountryInit] = useState();

  const [passwordSeen, setPasswordSeen] = useState(false);
  const handlePasswordSeen = () => {
    setPasswordSeen(!passwordSeen);
  };
  const history = useNavigate();
  const validate = () => {
    let error = [];
    let formIsValid = true;

    if (!formData.email) {
      formIsValid = false;
      error["email"] = "Please Enter Email";
    }
    if (!formData.password) {
      formIsValid = false;
      error["password"] = "Please Enter Password";
    }
    setErrors(error);
    return formIsValid;
  };

  const signupValidate = () => {
    let error = [];
    let formIsValid = true;

    if (!signupFormData.email) {
      formIsValid = false;
      error["email"] = "Please Enter Email";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(signupFormData.email)
    ) {
      formIsValid = false;
      error["email"] = "Please Enter proper Email";
    }

    if (!signupFormData.firstName) {
      formIsValid = false;
      error["firstName"] = "Please Enter FirstName";
    } else if (!/^[aA-zZ\s]+$/.test(signupFormData.firstName)) {
      formIsValid = false;
      error["firstName"] = "only alphabetic character are allowd";
    }
    if (!signupFormData.lastName) {
      formIsValid = false;
      error["lastName"] = "Please Enter LastName";
    } else if (!/^[aA-zZ\s]+$/.test(signupFormData.lastName)) {
      formIsValid = false;
      error["firstName"] = "only alphabetic character are allowd";
    }
    if (!signupFormData.password) {
      formIsValid = false;
      error["password"] = "Please Enter Password";
    }

    setSignupErrors(error);
    return formIsValid;
  };
  const handleSubmit = async (event) => {
    if ((event && event.keyCode === 13) || (event && event.type === "click")) {
      if (validate()) {
        const data = {
          email: formData.email,
          password: formData.password,
        };
        setLoading(true);
        await ApiPost("users/login", data)
          .then((res) => {
            if (res.data.message === "Successfully logged in.") {
              setLoading(false);
              authUtil.setToken(res.data.data.token);
              userUtil.setUserInfo(res.data.data);
              setLoginModal(false);
              window.location.reload();
            } else if (res.msg) {
              setLoading(false);
              setErrors({ user: "User does not exist." });
            } else {
              setLoading(false);
              setErrors({ user: "Please check username or password" });
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log("err", err);
          });
      }
    }
  };

  const shareLink = () => {
    navigator.clipboard.writeText(`${window.location.href}`);
    toast.success("Link Copied..");
  };

  const [copied, setCopied] = useState(false);

  function copy() {
    const el = document.createElement("input");
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  }

  const handleSignupSubmit = async (event) => {
    if ((event && event.keyCode === 13) || (event && event.type === "click")) {
      if (signupValidate()) {
        const data = {
          firstName: signupFormData.firstName,
          lastName: signupFormData.lastName,
          email: signupFormData.email,
          password: signupFormData.password,
          contact: signupFormData.contact,
          country: countryInit,
          countryCode: Idc,
        };
        setLoading(true);
        let err = [];

        await ApiPost("users/signup", data)
          .then((res) => {
            if (res?.data?.data === "Email has been already registered.") {
              err["error"] = res.data.data;
              setSignupErrors(err);
            }
            if (res.data.message === "Record Sucessfully Inserted.") {
              setLoading(false);
              authUtil.setToken(res.data.data.token);
              userUtil.setUserInfo(res.data.data);
              setSignupModal(false);
              window.location.reload();
            } else {
              setLoading(false);
              setErrors({ user: res.msg });
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log("err", err);
          });
      }
    }
  };
  const handleChange = (e) => {
    let { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSignupChange = (e) => {
    let { name, value } = e.target;

    setSignupFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const dispatch = useDispatch();

  useEffect(() => {
    getArtistData();
    getByUserPlaylist();
  }, [dataChange]);

  const getByUserPlaylist = async () => {
    await ApiGet("playlist/get-by-user")
      .then((res) => {
        if (res.status.data !== "Record list is empty") {
          setUserPlaylist(res.data.data);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddToPlaylist = async (id) => {
    setLoading(true);

    const data = {
      playlistId: id,
      musicId: playlistModal.musicId,
    };
    await ApiPost("playlist/add-to-playlist", data)
      .then((res) => {
        setLoading(false);
        console.log("response", res);
        if (res.data?.message?.includes("exists")) {
          toast.error("Song is already added to the playlist!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            closeOnTouchStart: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          setPlaylistModal({ open: false });
          toast.success("Song is added to the playlist!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            closeOnTouchStart: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  const getArtistData = async () => {
    const data = {
      id: artistId,
    };
    setLoading(true);
    await ApiPost("artist/get-artist-by-id", data)
      .then((res) => {
        setLoading(false);
        console.log("res", res.data.data);
        setArtistData(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  const handleBack = () => {
    history("/");
    window.location.reload();
  };

  const [open, setOpen] = useState({});
  const NavigatorRef2 = useRef();

  useOnClickOutside(NavigatorRef2, () => {
    setOpen({});
  });

  // play all free songs on a list one by one from page to page 
  const handlePlaySongs = (record, key) => {
    if (record.isPaid) {
       if (authUtil.isLoggedIn()) {
        setShowModal({
          musicId: record._id,
          open: true,
          amount: record.amount,
          musicTitle: record.title,
          artistId: record.artistData?._id,
        });
       } else {
        setLoginModal(true);
       }
    } else {
      dispatch(getMusicData({playlistData: artistData, index: key}));
    }
  };

  return (
    <div>
      <>
        <Header />
      </>
      <div className="flex">
        <>
          <Sidebar />
        </>
        <main className="s-layout__content">
          <div className="main-box">
            <div className="container-fluid">
              <div className="md:flex pt-16">
                <div className="md:w-full pt-6 flex items-center">
                  <div
                    // onClick={() => {
                    //   handleBack();
                    // }}
                    // OnTouchStart={() => {
                    //   handleBack();
                    // }}
                    onClick={(e) => {
                      e.preventDefault();
                      history(-1);
                    }} 
                    
                    OnTouchStart={(e) => {
                      e.preventDefault();
                      history(-1);
                    }}                        
                  
                  >
                    <p>
                      <i className="fas fa-arrow-left cursor-pointer icon-font-size text-white"></i>
                    </p>
                  </div>
                  <p className="font-size-35 white-text-color font-medium tracking-normal text-center mb-0 pl-5">
                    Artists Profile
                  </p>
                </div>
              </div>
              <div className="lg:flex pt-5">
                <div className="lg:w-2/6 pl-3 pr-3 m-pl-0 m-pr-0">
                  <div className="md:flex">
                    <div className="md:w-full">
                      <div className="main-profile-img flex justify-center">
                        <img
                          src={
                            artistData?.profile
                              ? artistData?.profile
                              : avatar
                          }
                          alt=""
                        />
                      </div>
                      <div className="cursor-pointer">
                        
                        <p className="font-size-18 white-text-color font-normal tracking-normal text-left mt-3">
                          Name :
                          <span className="font-bold pl-3">
                            {artistData?.artistName}
                            {/* {artistData?.realName} */}
                          </span>
                        </p>
                        <p className="font-size-18 white-text-color font-normal tracking-normal text-left mt-3">
                          Description :
                          <span className="font-bold pl-3">
                            {artistData?.desc === "undefined"
                              ? "N/A"
                              : artistData?.desc}
                          </span>
                        </p>
                        <p className="font-size-18 white-text-color font-normal tracking-normal text-left mt-3">
                          Country :
                          <span className="font-bold pl-3">
                            {artistData?.country || "N/A"}
                          </span>
                          <span className="font-bold pl-3">
                            <div className="pt-4">
                              <button
                                className="login-modal-btn font-size-18 white-text-color font-normal tracking-normal cursor-pointer"
                                type="submit"
                                onClick={() =>
                                  authUtil.isLoggedIn()
                                    ? setPaymentModal({
                                        artistId: artistData._id,
                                        open: true,
                                      })
                                    : setLoginModal(true)
                                }
                                OnTouchStart={() =>
                                  authUtil.isLoggedIn()
                                    ? setPaymentModal({
                                        artistId: artistData._id,
                                        open: true,
                                      })
                                    : setLoginModal(true)
                                }
                              >
                                Support This Artist
                              </button>
                            </div>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full pl-3 pr-3 m-pl-0 m-pr-0 m-pt-2 profile-music-align">
                  <div className="profile-show ">
                    <div className="md:flex mb-8 ">
                      <div className="md:w-full pl-3 pr-3 m-pl-0 m-pr-0">
                        <div className="flex items-center justify-between mobile-view-space">
                          <p className="font-size-30 white-text-color font-medium tracking-normal cursor-pointer">
                            Music
                          </p>
                          <p className="font-size-16 tracking-normal font-normal white-text-color">
                            Total music {artistData?.music?.length}
                          </p>
                        </div>
                        <div className="profile-bottom-border mt-3"></div>
                        <div className="cus-grid-one pt-15">
                          {artistData?.music?.length === 0 ? (
                            <div className="flex justify-center">
                              <h1 className="text-white">No music found</h1>
                            </div>
                          ) : artistData?.music?.length 
                          ? artistData
                            // ?.slice(0, 20)
                            ?.music.map((rec, index) => {
                              return (
                                <div key={index}
                                className="grid-items">
                                  <div
                                    className="music-profile-img"
                                    onClick={() =>
                                      rec.isPaid
                                        ? authUtil.isLoggedIn()
                                          ? userUtil
                                              ?.getMusicData()
                                              ?.includes(rec._id)
                                            ? dispatch(getMusicData({
                                                  musicUrl: rec.musicUrl,
                                                  title: rec.musicTitle,
                                                  playlistData: artistData.music, // to play all music in the artist list continuously and the next button includes the
                                                  index: index,                  // to recognize the song clicked other wise it will always be the first song on the list that will be playing
                                                  artistData: {
                                                    artistName:
                                                      artistData.artistName,
                                                    realName:
                                                      artistData.realName,
                                                  },
                                                })
                                              )
                                            : setShowModal({
                                                musicId: rec._id,
                                                open: true,
                                                amount: rec.amount,
                                                musicTitle: rec.musicTitle,
                                                artistId: rec.artistId,
                                              })
                                          : setLoginModal(true)
                                        : dispatch(
                                            getMusicData({
                                              musicUrl: rec.musicUrl,
                                              title: rec.musicTitle,
                                              playlistData: artistData.music, // to play all music in the artist list continuously and the next button includes the
                                              index: index,                   // to recognize the song clicked other wise it will always be the first song on the list that will be playing
                                              artistData: {
                                                artistName:
                                                  artistData.artistName,
                                                realName: artistData.realName,
                                              },
                                            })
                                          )
                                    }
                                    OnTouchStart={() =>
                                      rec.isPaid
                                        ? authUtil.isLoggedIn()
                                          ? userUtil
                                              ?.getMusicData()
                                              ?.includes(rec._id)
                                            ? dispatch(
                                                getMusicData({
                                                  musicUrl: rec.musicUrl,
                                                  title: rec.musicTitle,
                                                  playlistData: artistData.music, // to play all music in the artist list continuously and the next button includes the
                                                  index: index,                  // to recognize the song clicked other wise it will always be the first song on the list that will be playing
                                                  artistData: {
                                                    artistName:
                                                      artistData.artistName,
                                                    realName:
                                                      artistData.realName,
                                                  },
                                                })
                                              )
                                            : setShowModal({
                                                musicId: rec._id,
                                                open: true,
                                                amount: rec.amount,
                                                musicTitle: rec.musicTitle,
                                                artistId: rec.artistId,
                                              })
                                          : setLoginModal(true)
                                        : dispatch(
                                            getMusicData({
                                              musicUrl: rec.musicUrl,
                                              title: rec.musicTitle,
                                              playlistData: artistData.music, // to play all music in the artist list continuously and the next button includes the
                                              index: index,                   // to recognize the song clicked other wise it will always be the first song on the list that will be playing
                                              artistData: {
                                                artistName:
                                                  artistData.artistName,
                                                realName: artistData.realName,
                                              },
                                            })
                                          )
                                    }
                                  >
                                    <img src={rec?.musicImage} alt="" />
                                  </div>
                                  <div className="music-profile-text p-4">
                                    <div className="flex items-center justify-between cursor-pointer">
                                      <div
                                        onClick={() =>
                                          rec.isPaid
                                            ? authUtil.isLoggedIn()
                                              ? userUtil
                                                  ?.getMusicData()
                                                  ?.includes(rec._id)
                                                ? dispatch(
                                                    getMusicData({
                                                      musicUrl: rec.musicUrl,
                                                      title: rec.musicTitle,
                                                      artistData: {
                                                        artistName:
                                                          artistData.artistName,
                                                        realName:
                                                          artistData.realName,
                                                      },
                                                    })
                                                  )
                                                : setShowModal({
                                                    musicId: rec._id,
                                                    open: true,
                                                    amount: rec.amount,
                                                    musicTitle: rec.musicTitle,
                                                    artistId:
                                                      rec.artistData._id,
                                                  })
                                              : setLoginModal(true)
                                            : dispatch(
                                                getMusicData({
                                                  musicUrl: rec.musicUrl,
                                                  title: rec.musicTitle,
                                                  artistData: {
                                                    artistName:
                                                      artistData.artistName,
                                                    realName:
                                                      artistData.realName,
                                                  },
                                                })
                                              )
                                        }
                                        OnTouchStart={() =>
                                          rec.isPaid
                                            ? authUtil.isLoggedIn()
                                              ? userUtil
                                                  ?.getMusicData()
                                                  ?.includes(rec._id)
                                                ? dispatch(
                                                    getMusicData({
                                                      musicUrl: rec.musicUrl,
                                                      title: rec.musicTitle,
                                                      artistData: {
                                                        artistName:
                                                          artistData.artistName,
                                                        realName:
                                                          artistData.realName,
                                                      },
                                                    })
                                                  )
                                                : setShowModal({
                                                    musicId: rec._id,
                                                    open: true,
                                                    amount: rec.amount,
                                                    musicTitle: rec.musicTitle,
                                                    artistId:
                                                      rec.artistData._id,
                                                  })
                                              : setLoginModal(true)
                                            : dispatch(
                                                getMusicData({
                                                  musicUrl: rec.musicUrl,
                                                  title: rec.musicTitle,
                                                  artistData: {
                                                    artistName:
                                                      artistData.artistName,
                                                    realName:
                                                      artistData.realName,
                                                  },
                                                })
                                              )
                                        }
                                      >
                                        <p className="font-size-18 white-text-color font-normal tracking-normal mb-0">
                                          {rec?.musicTitle}
                                        </p>
                                        {/* <p className="font-size-18 gray-text-color font-normal tracking-normal mb-0">
                                          Views: {rec?.views}
                                        </p> */}
                                      </div>

                                      <div className="flex justify-between">
                                        {(() => {
                                          if (authUtil.isLoggedIn()) {
                                            return rec.isPaid ? (
                                              userUtil
                                                ?.getMusicData()
                                                ?.includes(rec._id) ? (
                                                <div
                                                  className="menu_dd menu_dd_profile"
                                                  ref={NavigatorRef2}
                                                >
                                                  <div
                                                    className="menu-img"
                                                    onClick={() => {
                                                      setOpen((pre) => {
                                                        return {
                                                          ...pre,
                                                          [`newrel${rec._id}`]:
                                                            !pre[
                                                              `newrel${rec._id}`
                                                            ],
                                                        };
                                                      });
                                                    }}
                                                  >
                                                    <img
                                                      src={Menu}
                                                      style={{ height: "25px" }}
                                                      alt=""
                                                    />
                                                  </div>
                                                  {open[`newrel${rec._id}`] && (
                                                    <div
                                                      className="menu-box"
                                                      // ref={NavigatorRef1}
                                                    >
                                                      <div
                                                        className="text-white p-2 cursor-pointer"

                                                        onClick={() =>
                                                          authUtil.isLoggedIn()
                                                            ? setPlaylistModal({
                                                                musicId:
                                                                  rec._id,
                                                                open: true,
                                                              })
                                                            : setLoginModal(
                                                                true
                                                              )
                                                        }
                                                        OnTouchStart={() =>
                                                          authUtil.isLoggedIn()
                                                            ? setPlaylistModal({
                                                                musicId:
                                                                  rec._id,
                                                                open: true,
                                                              })
                                                            : setLoginModal(
                                                                true
                                                              )
                                                        }
                                                      >
                                                        Add to playlist
                                                      </div>

                                                      <div
                                                        className="text-white p-2"
                                                        onClick={() =>
                                                          dispatch(getMusicData(rec, index))
                                                        }
                                                        OnTouchStart={() =>
                                                          dispatch(getMusicData(rec, index))
                                                        }
                                                      >
                                                        Play song now
                                                      </div>
                                                      <div
                                                        className="text-white p-2 cursor-pointer"
                                                        onClick={() =>
                                                          history(
                                                            `/song/${rec._id}`
                                                          )
                                                        }
                                                        OnTouchStart={() =>
                                                          history(
                                                            `/song/${rec._id}`
                                                          )
                                                        }
                                                      >
                                                        Details
                                                      </div>
                                                      {/* {!rec.isPaid && ( */}
                                                      <div
                                                        className="text-white p-2 cursor-pointer"
                                                        onClick={() => {
                                                          setDownloadId(
                                                            rec._id
                                                          );
                                                          authUtil.isLoggedIn()
                                                            ? handledownload(
                                                                rec, index
                                                              )
                                                            : setLoginModal(
                                                                true
                                                              );
                                                        }}
                                                        OnTouchStart={() => {
                                                          setDownloadId(
                                                            rec._id
                                                          );
                                                          authUtil.isLoggedIn()
                                                            ? handledownload(
                                                                rec, index
                                                              )
                                                            : setLoginModal(
                                                                true
                                                              );
                                                        }}
                                                      >
                                                        Download{" "}
                                                        {console.log(
                                                          "downloading1",
                                                          rec.musicUrl,
                                                          rec.musicTitle
                                                        )}
                                                      </div>
                                                    {/* )} */}
                                                    <div
                                                      className="text-white p-2 cursor-pointer"
                                                      onClick={() => {
                                                        shareLink(rec._id);
                                                      }}
                                                      OnTouchStart={() => {
                                                        shareLink(rec._id);
                                                      }}
                                                    >
                                                      <button onClick={copy} OnTouchStart={copy}>
                                                        {!copied
                                                          ? "Copy link"
                                                          : "Copy link"}
                                                      </button>
                                                    </div>
                                                    </div>
                                                  )}
                                                </div>
                                              ) : (
                                                <div
                                                  onClick={() =>
                                                    authUtil.isLoggedIn()
                                                      ? setShowModal({
                                                          musicId: rec._id,
                                                          open: true,
                                                          amount: rec.amount,
                                                          musicTitle:
                                                            rec.musicTitle,
                                                          artistId:
                                                            rec.artistId,
                                                        })
                                                      : setLoginModal(true)
                                                  }
                                                  OnTouchStart={() =>
                                                    authUtil.isLoggedIn()
                                                      ? setShowModal({
                                                          musicId: rec._id,
                                                          open: true,
                                                          amount: rec.amount,
                                                          musicTitle:
                                                            rec.musicTitle,
                                                          artistId:
                                                            rec.artistId,
                                                        })
                                                      : setLoginModal(true)
                                                  }
                                                >
                                                  <i className="fas fa-crown yellow-icon-color cursor-pointer font-size-18"></i>
                                                </div>
                                              )
                                            ) : (
                                              <div
                                                className="menu_dd menu_dd_profile"
                                                ref={NavigatorRef2}
                                              >
                                                <div
                                                  className="menu-img"
                                                  onClick={() => {
                                                    setOpen((pre) => {
                                                      return {
                                                        ...pre,
                                                        [`newrel${rec._id}`]:
                                                          !pre[
                                                            `newrel${rec._id}`
                                                          ],
                                                      };
                                                    });
                                                  }}
                                                >
                                                  <img
                                                    src={Menu}
                                                    style={{ height: "25px" }}
                                                    alt=""
                                                  />
                                                </div>
                                                {open[`newrel${rec._id}`] && (
                                                  <div
                                                    className="menu-box"
                                                    // ref={NavigatorRef1}
                                                  >
                                                    <div
                                                      className="text-white p-2 cursor-pointer"
                                                      onClick={() =>
                                                        authUtil.isLoggedIn()
                                                          ? setPlaylistModal({
                                                              musicId: rec._id,
                                                              open: true,
                                                            })
                                                          : setLoginModal(true)
                                                      }
                                                      OnTouchStart={() =>
                                                        authUtil.isLoggedIn()
                                                          ? setPlaylistModal({
                                                              musicId: rec._id,
                                                              open: true,
                                                            })
                                                          : setLoginModal(true)
                                                      }
                                                    >
                                                      Add to playlist
                                                    </div>

                                                    <div
                                                      className="text-white p-2"
                                                      onClick={() =>
                                                        dispatch(getMusicData(rec))
                                                      }
                                                      OnTouchStart={() =>
                                                        dispatch(getMusicData(rec))
                                                      }
                                                    >
                                                      Play song now
                                                    </div>
                                                    <div
                                                      className="text-white p-2 cursor-pointer"
                                                      onClick={() =>
                                                        history(
                                                          `/song/${rec._id}`
                                                        )
                                                      }
                                                      OnTouchStart={() =>
                                                        history(
                                                          `/song/${rec._id}`
                                                        )
                                                      }
                                                    >
                                                      Details
                                                    </div>
                                                    {!rec.isPaid && (
                                                      <div
                                                        className="text-white p-2 cursor-pointer"
                                                        onClick={() => {
                                                          setDownloadId(
                                                            rec._id
                                                          );
                                                          authUtil.isLoggedIn()
                                                            ? handledownload(
                                                                rec, index
                                                              )
                                                            : setLoginModal(
                                                                true
                                                              );
                                                        }}
                                                        OnTouchStart={() => {
                                                          setDownloadId(
                                                            rec._id
                                                          );
                                                          authUtil.isLoggedIn()
                                                            ? handledownload(
                                                                rec, index
                                                              )
                                                            : setLoginModal(
                                                                true
                                                              );
                                                        }}
                                                      >
                                                        Download{" "}
                                                        {console.log(
                                                          "downloading1",
                                                          rec.musicUrl,
                                                          rec.musicTitle
                                                        )}
                                                      </div>
                                                    )}
                                                    <div
                                                      className="text-white p-2 cursor-pointer"
                                                      onClick={() => {
                                                        shareLink(rec._id);
                                                      }}
                                                      OnTouchStart={() => {
                                                        shareLink(rec._id);
                                                      }}
                                                    >
                                                      <button onClick={copy} OnTouchStart={copy}>
                                                        {!copied
                                                          ? "Copy link"
                                                          : "Copy link"}
                                                      </button>
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            );
                                          } else {
                                            return rec.isPaid ? (
                                              <div
                                                onClick={() =>
                                                  authUtil.isLoggedIn()
                                                    ? setShowModal({
                                                        musicId: rec._id,
                                                        open: true,
                                                        amount: rec.amount,
                                                        musicTitle:
                                                          rec.musicTitle,
                                                        artistId:
                                                          rec.artistData._id,
                                                      })
                                                    : setLoginModal(true)
                                                }
                                                OnTouchStart={() =>
                                                  authUtil.isLoggedIn()
                                                    ? setShowModal({
                                                        musicId: rec._id,
                                                        open: true,
                                                        amount: rec.amount,
                                                        musicTitle:
                                                          rec.musicTitle,
                                                        artistId:
                                                          rec.artistData._id,
                                                      })
                                                    : setLoginModal(true)
                                                }
                                              >
                                                <i className="fas fa-crown yellow-icon-color cursor-pointer font-size-18"></i>
                                              </div>
                                            ) : (
                                              <div
                                                className="menu_dd menu_dd_profile"
                                                ref={NavigatorRef2}
                                              >
                                                <div
                                                  className="menu-img"
                                                  onClick={() => {
                                                    setOpen((pre) => {
                                                      return {
                                                        ...pre,
                                                        [`newrel${rec._id}`]:
                                                          !pre[
                                                            `newrel${rec._id}`
                                                          ],
                                                      };
                                                    });
                                                  }}
                                                >
                                                  <img
                                                    src={Menu}
                                                    style={{ height: "25px" }}
                                                    alt=""
                                                  />
                                                </div>
                                                {open[`newrel${rec._id}`] && (
                                                  <div
                                                    className="menu-box"
                                                    // ref={NavigatorRef1}
                                                  >
                                                    <div
                                                      className="text-white p-2 cursor-pointer"
                                                      onClick={() =>
                                                        authUtil.isLoggedIn()
                                                          ? setPlaylistModal({
                                                              musicId: rec._id,
                                                              open: true,
                                                            })
                                                          : setLoginModal(true)
                                                      }
                                                      OnTouchStart={() =>
                                                        authUtil.isLoggedIn()
                                                          ? setPlaylistModal({
                                                              musicId: rec._id,
                                                              open: true,
                                                            })
                                                          : setLoginModal(true)
                                                      }
                                                    >
                                                      Add to playlist
                                                    </div>

                                                    <div
                                                      className="text-white p-2 cursor-pointer"
                                                      onClick={() =>
                                                        dispatch(getMusicData(rec, index))
                                                      }
                                                      OnTouchStart={() =>
                                                        dispatch(getMusicData(rec, index))
                                                      }
                                                    >
                                                      Play song now
                                                    </div>
                                                    <div
                                                      className="text-white p-2 cursor-pointer"
                                                      onClick={() =>
                                                        history(
                                                          `/song/${rec._id}`
                                                        )
                                                      }
                                                      OnTouchStart={() =>
                                                        history(
                                                          `/song/${rec._id}`
                                                        )
                                                      }
                                                    >
                                                      Details
                                                    </div>
                                                    {!rec.isPaid && (
                                                      <div
                                                        className="text-white p-2 cursor-pointer"
                                                        onClick={() => {
                                                          setDownloadId(
                                                            rec._id
                                                          );
                                                          authUtil.isLoggedIn()
                                                            ? handledownload(
                                                                rec
                                                              )
                                                            : setLoginModal(
                                                                true
                                                              );
                                                        }}
                                                        OnTouchStart={() => {
                                                          setDownloadId(
                                                            rec._id
                                                          );
                                                          authUtil.isLoggedIn()
                                                            ? handledownload(
                                                                rec
                                                              )
                                                            : setLoginModal(
                                                                true
                                                              );
                                                        }}
                                                      >
                                                        Download
                                                        {console.log(
                                                          "downloading2",
                                                          rec.musicUrl,
                                                          rec.musicTitle
                                                        )}
                                                      </div>
                                                    )}

                                                    <div
                                                      className="text-white p-2 cursor-pointer"
                                                      onClick={() => {
                                                        shareLink(rec._id);
                                                      }}
                                                      OnTouchStart={() => {
                                                        shareLink(rec._id);
                                                      }}
                                                    >
                                                      <button onClick={copy} OnTouchStart={copy}>
                                                        {!copied
                                                          ? "Copy link"
                                                          : "Copy link"}
                                                      </button>
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            );
                                          }
                                        })()}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                           : (
                            <div className="dots mt-10">
                              <div className="dot"></div>
                              <div className="dot"></div>
                              <div className="dot"></div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* //* Playlist modal */}
              {playlistModal.open ? (
                <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                      {/*content*/}
                      <div className="playlist border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div
                          className="justify-content-center p-5 relative  border-solid border-white-200 rounded-t"
                          // style={{
                          //   backgroundColor: "#090C10",
                          // }}
                        >
                          <h3 className="text-2xl text-center font-bold text-white">
                            Select Playlist
                          </h3>

                          <button
                            className="p-1 ml-auto bg-transparent border-0 text-white  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }
                            OnTouchStart={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }
                          >
                            <p className="button-center-modal">
                              <i className="fas fa-times"></i>
                            </p>
                          </button>
                        </div>
                        {/*body*/}
                        <div
                          className="relative p-6 flex-auto"
                          // style={{
                          //   backgroundColor: "#090C10",
                          // }}
                        >
                          <p className="my-4 text-white text-lg leading-relaxed">
                            <div
                              className="flex items-center flex-row flex-wrap pt-4"
                              style={{ height: "450px", overflowY: "overlay" }}
                            >
                              {userPlaylist && userPlaylist?.length ? (
                                userPlaylist.map((record, index) => {
                                  return (
                                    <div
                                      className="pl-3 pr-3 cursor-pointer "
                                      key={index}
                                      onClick={() => {
                                        handleAddToPlaylist(record._id);
                                      }}
                                      OnTouchStart={() => {
                                        handleAddToPlaylist(record._id);
                                      }}
                                    >
                                      <div className="singer-box">
                                        <div className="singer-img">
                                          <img
                                            src={
                                              (record &&
                                                record.Songs &&
                                                record.Songs?.length &&
                                                record.Songs[0].musicImage) ||
                                              `https://source.unsplash.com/1600x900/?${record.playlist_name}`
                                            }
                                            style={{
                                              height: "150px",
                                              width: "335px",
                                            }}
                                            alt=""
                                          />
                                        </div>
                                        <div className="singer-text p-4">
                                          <div className="flex items-center justify-between">
                                            <div>
                                              <p className="font-size-14 white-text-color font-normal tracking-normal mb-0">
                                                {record.playlist_name}
                                              </p>
                                            </div>
                                            <div>
                                              {record?.Songs?.map((rec) => {
                                                if (
                                                  rec._id ===
                                                  playlistModal.musicId
                                                ) {
                                                  return (
                                                    <>
                                                      <p className="cursor-pointer font-size-14 login-text-color">
                                                        added
                                                        <i className="pl-1 fas fa-check cursor-pointer font-size-14 login-text-color"></i>
                                                      </p>
                                                    </>
                                                  );
                                                }
                                              })}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="dots">
                                  <div className="dot"></div>
                                  <div className="dot"></div>
                                  <div className="dot"></div>
                                </div>
                              )}
                            </div>
                          </p>
                        </div>
                        {/*footer*/}
                        <div
                          className="flex items-center justify-end p-4 border-solid border-white rounded-b"
                          // style={{ backgroundColor: "#090C10" }}
                        >
                          <button
                            className="text-red-500 border-solid border-white font-bold uppercase px-6 py-2 text-sm  mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }
                            OnTouchStart={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
              ) : null}

              {/* //* Purchase modal */}
              {showModal.open ? (
                <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                      {/*content*/}
                      <div className="poppop border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div
                          className="flex items-start justify-between pt-5 pr-5 pl-5 border-solid border-white-100 rounded-t-lg"
                          // style={{
                          //   backgroundColor: "#0B0B0B",
                          // }}
                        >
                          <h3 className="text-2xl font-bold text-white">
                            Buy this song or album
                          </h3>
                          <button
                            className="p-1 ml-auto bg-transparent border-0 text-white  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() =>
                              setShowModal({
                                ...showModal,
                                open: false,
                              })
                            }
                            OnTouchStart={() =>
                              setShowModal({
                                ...showModal,
                                open: false,
                              })
                            }
                          >
                            <p>
                              <i className="fas fa-times"></i>
                            </p>
                          </button>
                        </div>
                        {/*body*/}
                        <div
                          className="relative pl-6 pr-6 pb-6 flex-auto rounded-b-lg"
                          // style={{
                          //   backgroundColor: "#0B0B0B",
                          // }}
                        >
                          <p className="mb-3 text-white leading-relaxed">
                            Like this artists music? Show your support by
                            purchasing their music.
                          </p>

                          <p className="mb-3 text-white text-lg leading-relaxed">
                            Title: {showModal.musicTitle}
                          </p>
                          <p className="mb-3 text-white text-lg leading-relaxed">
                            Price: ${showModal.amount}
                          </p>
                          <Elements stripe={stripePromise}>
                            <Payments
                              musicId={showModal.musicId}
                              amount={showModal.amount}
                              musicTitle={showModal.musicTitle}
                              musicArtistId={showModal.artistId}
                            />
                          </Elements>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
              ) : null}

              {paymentModal.open ? (
                <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                      {/*content*/}
                      <div className="poppop border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div
                          className="flex items-start justify-between p-5   border-solid border-white-100 rounded-t-lg"
                          // style={{ backgroundColor: "#090C10" }}
                        >
                          <h3 className="text-2xl font-bold text-white">
                            Donate To Artist
                          </h3>
                          <button
                            className="p-1 ml-auto bg-transparent border-0 text-white  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() =>
                              setPaymentModal({
                                ...paymentModal,
                                open: false,
                              })
                            }
                            OnTouchStart={() =>
                              setPaymentModal({
                                ...paymentModal,
                                open: false,
                              })
                            }
                          >
                            <p>
                              <i className="fas fa-times"></i>
                            </p>
                          </button>
                        </div>
                        {/*body*/}
                        <div
                          className="relative p-6 flex-auto rounded-b-lg"
                        >
                          
                          <Elements stripe={stripePromise}>
                            <Donate
                              musicId={paymentModal.musicId}
                              amount={paymentModal.amount}
                              musicTitle={paymentModal.musicTitle}
                              musicArtistId={paymentModal.artistId}
                            />
                          </Elements>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
              ) : null}
              {forgotModal ? (
                <Forgotpassword
                  setSignupModal={setSignupModal}
                  setLoginModal={setLoginModal}
                  setForgotModal={setForgotModal}
                />
              ) : null}

              {loginModal ? (
                <Userlogin
                  setSignupModal={setSignupModal}
                  setLoginModal={setLoginModal}
                  setForgotModal={setForgotModal}
                />
              ) : null}

              {signupModal ? (
                <UserSignup
                  setSignupModal={setSignupModal}
                  setLoginModal={setLoginModal}
                  setForgotModal={setForgotModal}
                />
              ) : null}
            </div>
          </div>
          {loading && <LoadingBalls />}
        </main>
      </div>
    </div>
  );
}
