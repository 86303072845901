/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */
import { useElements, useStripe, CardElement } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { ApiPost } from "../../../helpers/API/ApiData";
import CardInput from "./CardInput";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingBalls from "../../../Assets/LoadingBalls";
import { setMusicPurchased } from "../../../utils/user.util";

const Donate = (props) => {
  console.log("dsfsdfsgfdsgdsbgdsf", props.service);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const handleDataChange = (e) => {
    let { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validate = () => {
    let error = [];
    let formIsValid = true;

    if (!formData.email) {
      formIsValid = false;
      error["email"] = "Please Enter Email";
    }
    if (!formData.address) {
      formIsValid = false;
      error["address"] = "Please Enter address";
    }
    if (!formData.firstName) {
      formIsValid = false;
      error["firstName"] = "Please Enter firstName";
    }
    if (!formData.lastName) {
      formIsValid = false;
      error["lastName"] = "Please Enter lastName";
    }
    if (!formData.country) {
      formIsValid = false;
      error["country"] = "Please Enter country";
    }
    setErrors(error);
    return formIsValid;
  };
  console.log("props", props);
  const handleSubmit = async (event) => {
    if (validate()) {
      if (!stripe || !elements) {
        return;
      }
      setLoading(true);
      console.log("ff", formData.amount);

      const res = await ApiPost("payment/donate", {
        artistId: props.musicArtistId,
        email: formData.email,
        amount: formData.amount,
      });

      const clientSecret = res.data.data["client_secret"];
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            amount: props.amount,
          },
        },
      });
      if (result.error) {
        setLoading(false);
        // Show error to your customer (e.g., insufficient funds)
        console.log(result.error.message);
      } else {
        // The payment has been processed!
        if (result.paymentIntent.status === "succeeded") {
          const res = await ApiPost("payment/confirmDonation", {
            artistId: props.musicArtistId,
            firstName: formData.firstName,
            lastName: formData.lastName,
            description: formData.description,
            country: formData.country,
            address: formData.address,
            email: formData.email,
            amount: formData.amount,
            paymentIntentId: clientSecret,
          });

          setLoading(false);
          console.log("Money is in the bank!");
          toast.success("Payment successfully completed!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            closeOnTouchStart: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setMusicPurchased(props.musicId);
          setLoading(false);
          window.location.reload();
          // Show a success message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.
        }
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <form>
        <div className="flex flex-col items-center justify-center pb-3">
          <div className="flex my-2">
            <div className="flex flex-col items-start">
              <p
                style={{
                  color: "red",
                  top: "0px",
                  fontSize: "12px",
                }}
                className="mr-2"
              >
                {errors["firstName"]}
              </p>
              <input
                type="text"
                style={{
                  backgroundColor: "transparent",
                  borderColor: "green",
                  color: "#fff",
                  padding: "10px 15px",
                  width: "100%",
                }}
                placeholder="Enter First Name"
                name="firstName"
                className="rounded-lg border border-primary font-size-18 mr-2"
                onChange={(e) => handleDataChange(e)}
              />
            </div>
            <div className="flex flex-col items-start">
              <p
                style={{
                  color: "red",
                  top: "0px",
                  fontSize: "12px",
                }}
                className="ml-2"
              >
                {errors["lastName"]}
              </p>
              <input
                type="text"
                style={{
                  backgroundColor: "transparent",
                  borderColor: "green",
                  color: "#fff",
                  padding: "10px 15px",
                  width: "100%",
                }}
                placeholder="Enter Last Name "
                name="lastName"
                className="rounded-lg border border-primary font-size-18 ml-2"
                onChange={(e) => handleDataChange(e)}
              />
            </div>
          </div>
          <p
            style={{
              color: "red",
              top: "0px",
              fontSize: "12px",
            }}
          >
            {errors["address"]}
          </p>
          <input
            type="text"
            style={{
              color: "#fff",
              backgroundColor: "transparent",
              borderColor: "green",
              padding: "10px 15px",
              width: "100%",
            }}
            placeholder="Enter Country"
            name="country"
            className="rounded-lg border border-primary font-size-18 my-2"
            onChange={(e) => handleDataChange(e)}
          />
          <p
            style={{
              color: "red",
              top: "0px",
              fontSize: "12px",
            }}
          >
            {errors["address"]}
          </p>
          <input
            type="text"
            style={{
              color: "#fff",
              backgroundColor: "transparent",
              borderColor: "green",
              padding: "10px 15px",
              width: "100%",
            }}
            placeholder="Enter Address"
            name="address"
            className="rounded-lg border border-primary font-size-18 my-2"
            onChange={(e) => handleDataChange(e)}
          />
          <p
            style={{
              color: "red",
              top: "0px",
              fontSize: "12px",
            }}
            className="text-left"
          >
            {errors["email"]}
          </p>
          <input
            type="email"
            style={{
              color: "#fff",
              backgroundColor: "transparent",
              borderColor: "green",
              padding: "10px 15px",
              width: "100%",
            }}
            placeholder="Enter Email"
            name="email"
            className="rounded-lg border border-primary font-size-18 my-2"
            onChange={(e) => handleDataChange(e)}
          />
          <p
            style={{
              color: "red",
              top: "0px",
              fontSize: "12px",
            }}
          >
            {errors["description"]}
          </p>
          <input
            type="text"
            style={{
              color: "#fff",
              backgroundColor: "transparent",
              borderColor: "green",
              padding: "10px 15px",
              width: "100%",
            }}
            placeholder="Enter Description"
            name="description"
            className="rounded-lg border border-primary font-size-18 my-2"
            onChange={(e) => handleDataChange(e)}
          />
          <p
            style={{
              color: "red",
              top: "0px",
              fontSize: "12px",
            }}
            className="text-left"
          >
            {errors["amount"]}
          </p>
          <input
            type="number"
            style={{
              color: "#fff",
              backgroundColor: "transparent",
              borderColor: "green",
              padding: "10px 15px",
              width: "100%",
            }}
            placeholder="Enter amount ($)"
            name="amount"
            min={0}
            className="rounded-lg border border-primary font-size-18 my-2"
            onChange={(e) => handleDataChange(e)}
          />
        </div>
      </form>
      <div className="my-4">
        <CardInput />
      </div>
      <div className="flex items-center justify-end p-2 pt-2 pb-0 rounded-b">
        <button
          className="purchasebtn bg-success text-white  rounded font-bold uppercase px-6 py-2 text-sm  mr-1 mb-1 ease-linear transition-all duration-150"
          onClick={() => handleSubmit()}
          OnTouchStart={() => handleSubmit()}

          // disabled={isDisabled}
        >
          Donate
        </button>
      </div>
      {loading && <LoadingBalls />}
    </div>
  );
};

export default Donate;
