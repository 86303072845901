// src/Login.js
import React from 'react';
import { ApiGet, ApiPostNoAuth } from '../helpers/API/ApiData';
import * as authUtil from "@/utils/auth.util";
import * as userUtil from "@/utils/user.util";
import { toast } from 'react-toastify';
import { GoogleLogin } from '@react-oauth/google';

const GoogleSignUpCom = ({
  ...rest
}) => {
  const handleSignUpSuccess = async (credentialResponse) => {
    try {
      const response = await ApiPostNoAuth("signupwithgoogle/signup", { token: credentialResponse.credential, role: 'user' });
      const googleUserData = response.data;
      if (googleUserData) {
        authUtil.setToken(googleUserData.token);
        userUtil.setUserInfo({user: googleUserData.user});
        window.location.reload();
      }
    } catch (error) {
      console.log('8err Sign-Up: ', error);
      toast.error('🔴 Google Sign-Up went wrong!' + error);
    }
  }

  const handleSignUpError = () => {
    toast.error('🔴 Google Sign-Up went wrong!');
  };

  return (
    <span {...rest}>
      <GoogleLogin
        text="signup_with"
        onSuccess={handleSignUpSuccess}
        onError={handleSignUpError}
      />
    </span>
  );
};

export default GoogleSignUpCom;
