/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */

import React, { useState } from "react";
import { ApiPost, ApiGet } from "@/helpers/API/ApiData";
import * as authUtil from "../../../utils/auth.util";
import * as userUtil from "../../../utils/user.util";
import Country from "./../countries/Country";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import { checkParamsValidate, catchError } from "@/helpers/utils";
import GoogleSignUpCom from "@/commonComs/GoogleSignUpCom";
import AppleAuth from "@/commonComs/AppleAuth";

const StepEnum = {
  role: "role",
  signup: "signup",
  verifyEmail: "verifyEmail"
}

const UserSignup = (props) => {
  const recaptchaRef = React.createRef();
  const [loginInputs, setLoginInputs] = useState({});
  const [signupModal, setSignupModal] = useState(false);
  const [signupErrors, setSignupErrors] = useState([]);
  const [countryInit, setCountryInit] = useState("");
  const [step, setStep] = useState(StepEnum.role);
  const [Idc, setIdc] = useState();
  const [passwordSeen, setPasswordSeen] = useState(false);
  const handleChange = async (e) => {
    const { name, value } = e.target;
    setLoginInputs({ ...loginInputs, [name]: value });
  };

  const onSubmitCaptcha = () => {
    const recaptchaValue = recaptchaRef.current.getValue();
    onSubmitCaptcha(recaptchaValue);
  };

  const handlePasswordSeen = () => {
    setPasswordSeen(!passwordSeen);
  };

  const handleSignupSubmit = async (event) => {
    if ((event && event.keyCode === 13) || (event && event.type === "click")) {
      const data = {
        firstName: loginInputs.firstName,
        lastName: loginInputs.lastName,
        email: loginInputs.email,
        password: loginInputs.password,
        contact: loginInputs.contact,
        country: countryInit,
        countryCode: Idc,
      };
      // verify data fields
      const [dataError] = await catchError(checkParamsValidate(['firstName', 'lastName', 'email', 'password'], data));
      if(dataError) {
        toast.error(dataError.message);
        return;
      }
      let err = [];
      await ApiPost("users/signup", data)
        .then((res) => {
          const resData = res?.data?.data || {};
          if (resData === "Email has been already registered.") {
            err["error"] = resData;
            toast.error(resData);
            setSignupErrors(err);
          } else if (resData.action === "verify_email") {
            setStep(StepEnum.verifyEmail);
          } else if (res.data.message === "Record Sucessfully Inserted.") {
            authUtil.setToken(resData.token);
            userUtil.setUserInfo(resData);
            setSignupModal(false);
            window.location.reload();
          }
        })
        .catch((err) => {
          toast.error(err.message);
          console.log("err", err);
        });
    }
  };

  const beforeSignupSubmit = async () => {
    try {
      await ApiGet("auth/email/verify", {
        email: loginInputs.email,
        code: loginInputs.verificationCode
      });
      await handleSignupSubmit({
        type: 'click'
      });
    } catch(err) {
      toast.error(err?.response?.data?.message);
    }
  }

    
  return (
    <div>
      <div className="animation justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-full my-6 mx-auto lg:w-5/12 md:w-3/5 sm:w-1/2">
          <div className="relative modal-login-box border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 rounded-t">
              <h3 className="text-2xl font-bold text-white"> </h3>

              <button
                className="p-1 ml-auto bg-transparent border-0 text-white  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => {props.setSignupModal(false); }}
                OnTouchStart={() => {props.setSignupModal(false); }}

              >
                <p>
                  <i className="fas fa-times login-text-color relative"></i>
                </p>
              </button>
            </div>
            <div
              className="relative p-6 h-full sm:p-8 mb-24">
                <div className="login_logo pl-5 pr-5 flex justify-center relative pb-6">
                  <img
                    src={
                      require("../../../Assets/Images/login-logo.png").default
                    }
                    alt=""
                  />
                </div>
                <p className="text-center font-size-14">
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    {signupErrors["error"]}
                  </span>
                </p>

                {step === StepEnum.role && (
                  <div className="selection box p-4 flex justify-center">
                    <div>
                      <div
                        className="role-item"
                        onClick={() => {
                          setStep(StepEnum.signup);
                        }}
                        OnTouchStart={() => {
                          setStep(StepEnum.signup);
                        }}
                      >
                        Listener
                      </div>
                      <div className="role-item ">
                        <a
                          href="https://admin.7thcentury.co.uk/auth/registration"
                          target="_blank" rel="noreferrer"
                        >
                          Artist / Manager / Affiliate
                        </a>
                      </div>
                    </div>
                  </div>
                )}

                {step === StepEnum.signup && (
                  <div className="flex flex-col items-left justify-center login-placeholder pt-5">
                    <form>
                      <div className="">
                        <span
                          style={{
                            color: "red",
                            top: "0px",
                            fontSize: "12px",
                          }}
                        >
                          {signupErrors["firstName"]}
                        </span>
                        <input
                          type="text"
                          name="firstName"
                          className="form-control-login mb-8"
                          placeholder="Enter firstName"
                          onChange={(e) => handleChange(e)}
                          pattern="[A-Za-z]{3}"
                        />{" "}
                      </div>

                      <div className="">
                        <span
                          style={{
                            color: "red",
                            top: "0px",
                            fontSize: "12px",
                          }}
                        >
                          {signupErrors["lastName"]}
                        </span>
                        <input
                          type="text"
                          name="lastName"
                          className="form-control-login mb-8"
                          placeholder="Enter lastName"
                          onChange={(e) => handleChange(e)}
                        />{" "}
                      </div>

                      <div className="signup-modal-grid">
                        <div className="signup-modal-grid-items">
                          <div className="">
                            <span
                              style={{
                                color: "red",
                                top: "0px",
                                fontSize: "12px",
                              }}
                            >
                              {signupErrors["Idc"]}
                            </span>
                            {/* <div className="text-color-green width:100%"> */}
                            <div className="text-color-green" style={{ width: "100%" }}>

                              <select
                                value={Idc}
                                className={`form-control-login1 mb-8`}
                                onChange={(e) => {
                                  e.preventDefault();
                                  setIdc(e.target.value);
                                }}
                                // style={{ color: "green" }}
                                style={{ color: "green", minWidth: "120px", maxWidth: "180px", appearance: "none" }} // Set the desired width here
                                >
                                <option default>Country</option>
                                {Country.Country &&
                                  Country.Country.map((e, key) => {
                                    return (
                                      <option key={key} value={e.Key}>
                                        {e.Idc} {"  "} &nbsp;
                                        {e.Name}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                        </div> 
                        <div className="signup-modal-grid-items">
                          <div className="">
                            <span
                              style={{
                                color: "red",
                                top: "0px",
                                fontSize: "12px",
                              }}
                            >
                              {signupErrors["contact"]}
                            </span>
                            <input
                              type="tel"
                              name="contact"
                              className="form-control-login mb-8"
                              placeholder="Enter contact"
                              onChange={(e) => handleChange(e)}
                              onKeyPress={(e) => {
                                if (e.target.value.toString().length > 9)
                                  e.preventDefault();
                                if (
                                  e.which !== 8 &&
                                  e.which !== 0 &&
                                  (e.which < 48 || e.which > 57)
                                )
                                  e.preventDefault();
                              }}
                              required
                              maxLength="10"
                              minLength="10"
                              style={{ float: "right", minWidth: "120px", width: "215px" }} // Set the desired width here

                            />{" "}
                          </div>
                        </div>
                      </div>

                      <div className="">
                        <span
                          style={{
                            color: "red",
                            top: "0px",
                            fontSize: "12px",
                          }}
                        >
                          {signupErrors["email"]}
                        </span>
                        <input
                          type="email"
                          name="email"
                          className="form-control-login mb-8"
                          placeholder="Enter email"
                          onChange={(e) => handleChange(e)}
                        />{" "}
                      </div>

                      <div className="">
                        <span
                          style={{
                            color: "red",
                            top: "0px",
                            fontSize: "12px",
                          }}
                        >
                          {signupErrors["Country"]}
                        </span>
                        <div className="text-color-green">
                          <select
                            value={countryInit}
                            className={`form-control-login1 mb-8`}
                            onChange={(e) => {
                              e.preventDefault();
                              setCountryInit(e.target.value);
                            }}
                            style={{
                              color: "green",
                            }}
                          >
                            {" "}
                            <option>select country</option>
                            {Country.Country &&
                              Country.Country.map((e, key) => {
                                return (
                                  <option key={key} value={e.Key}>
                                    {e.Name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>

                      <div className="">
                        <span
                          style={{
                            color: "red",
                            top: "5px",
                            fontSize: "12px",
                          }}
                        >
                          {signupErrors["password"]}
                        </span>
                        <input
                          type={passwordSeen ? "text" : "password"}
                          name="password"
                          className="form-control-login mb-8"
                          placeholder="Enter password"
                          style={{ width: "85%" }}
                          onChange={(e) => handleChange(e)}
                        />{" "}
                        <i
                          className={
                            passwordSeen
                              ? "fa fa-eye-slash font-size-30 pl-3 mt-2"
                              : "fa fa-eye font-size-30 pl-3 mt-2"
                          }
                          aria-hidden="true"
                          onClick={() => handlePasswordSeen()}
                          OnTouchStart={() => handlePasswordSeen()}
                          style={{
                            color: "white",
                          }}
                          
                        ></i>
                      </div>
                    </form>

                    <div className="flex flex-col sm:flex-col md:flex-row items-center md:items-stretch justify-center">
                      <GoogleSignUpCom className="md:mr-5 sm:mb-0 mb-4" />
                      <AppleAuth />
                    </div>

                    <div className="flex justify-center mt-5">
                      <button
                        className="login-modal-btn font-size-16 white-text-color font-normal tracking-normal cursor-pointer"
                        onClick={handleSignupSubmit}
                        OnTouchStart={handleSignupSubmit}
                        type="submit"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                )}

                {
                  step === StepEnum.verifyEmail && (
                    <div className="w-4/6 m-auto">
                      <p className="text-center text-xl text-white mb-10">We have sent a verification code to your email, please check your email and enter the code below</p>
                      <form>
                        <div className="">
                          <span
                            style={{
                              color: "red",
                              top: "0px",
                              fontSize: "12px",
                            }}
                          >
                            {signupErrors["verificationCode"]}
                          </span>
                          <input
                            type="text"
                            name="verificationCode"
                            className="form-control-login mb-8"
                            placeholder="Enter code"
                            onChange={handleChange}
                          />
                        </div>
                      </form>
                      <div className="flex justify-center">
                      <button
                        className="login-modal-btn font-size-16 white-text-color font-normal tracking-normal cursor-pointer"
                        onClick={beforeSignupSubmit}
                        OnTouchStart={beforeSignupSubmit}
                        type="submit"
                      >
                        Sign Up
                      </button>
                    </div>
                    </div>
                  )
                }

                {/* <form onSubmit={onSubmitCaptcha}>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6Lfvxc0iAAAAAPDm4-ACjkiKiGBxH2ufflnbLaUt"
                    onChange={(e) => onChangeRecaptcha(e)}
                  />
                </form> */}
                <div>
                  <div>
                    <p className="font-size-18 white-text-color font-normal tracking-normal mb-0 text-center mt-4">
                      already have an account ?
                    </p>
                    <p
                      className="font-size-16 login-text-color font-normal mt-3 mb-8 tracking-normal text-center cursor-pointer"
                      onClick={() => {
                        props.setSignupModal(false);
                        props.setLoginModal(true);
                      }}
                      OnTouchStart={() => {
                        props.setSignupModal(false);
                        props.setLoginModal(true);
                      }}
                    >
                      Login
                    </p>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSignup;
