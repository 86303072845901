import { combineReducers } from "redux";
import NewRelease from "./NewRelease";
import AllTrending from "./AllTrending";
import AllFree from "./AllFree";
import AllArtist from "./Artists";
import AllPlaylist from "./AllPlaylist";
import MusicData from "./MusicData";
import UserProfile from "./UserProfile";
import AllGenre from "./AllGenre";
import AllMyPlaylist from './AllMyPlaylist';
import MusicByCountry from './MusicByCountry'
import AllVideos from './AllVideos'
import AllAlbum from './AllAlbum'
import MyMusics from "./MyMusics";

const rootReducer = combineReducers({
  NewRelease: NewRelease,
  AllTrending: AllTrending,
  AllFree: AllFree,
  AllArtist: AllArtist,
  AllPlaylist: AllPlaylist,
  MusicData: MusicData,
  ProfilePicture: UserProfile,
  AllGenre: AllGenre,
  AllMyPlaylist:AllMyPlaylist,
  MusicByCountry:MusicByCountry,
  AllVideos: AllVideos,
  AllAlbum: AllAlbum,
  MyMusics: MyMusics
});

export default rootReducer;
