import React, { useEffect } from 'react';
import { ApiPostNoAuth } from '../helpers/API/ApiData';
import * as authUtil from "@/utils/auth.util";
import * as userUtil from "@/utils/user.util";
import { toast } from 'react-toastify';

const AppleAuth = ({ onSuccess, onError, isLogin, ...rest }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
    script.async = true;
    script.onload = initializeAppleButton;
    document.body.appendChild(script);
    
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const initializeAppleButton = () => {
    window.AppleID.auth.init({
      clientId: 'com.7thcentury.co.uk',
      scope: 'email',
      redirectURI: window.location.origin + '/auth/apple/callback',
      state: 'state',
      usePopup: true
    });
    
    document.addEventListener('AppleIDSignInOnSuccess', (event) => {
      console.log('apple event: ', event);
      if(onSuccess) onSuccess(event.detail);
      else handleAppleCallback(event.detail);
    });
    
    document.addEventListener('AppleIDSignInOnFailure', (error) => {
      console.log('apple error: ', error);
      toast.error('🔴 Apple Auth went wrong!' + error);
      if(onError) onError(error);
    });
  };

  const handleAppleLogin = () => {
    window.AppleID.auth.signIn();
  };

  const handleAppleCallback = async (authData = {}) => {
    try {
      const { data } = await ApiPostNoAuth(`auth/apple/${isLogin? 'login' :'signup'}`, { code: authData.authorization?.code });
      if (data) {
        authUtil.setToken(data.data?.token);
        userUtil.setUserInfo({user: data.data?.user});
        window.location.reload();
      }
    } catch (error) {
      toast.error('🔴 Apple Auth went wrong!' + error);
      console.error('Login error:', error);
    }
  };

  return (
    <button
      {...rest}
      className={`px-4 py-2 white-text-color font-normal tracking-normal cursor-pointer ${rest.className}`}
      type="button"
      style={{ backgroundColor: "black", color: "white", borderRadius: "4px", width: "192px" }}
      onClick={handleAppleLogin}
    >
      <i className="fab fa-apple mr-2 text-xl"></i> {isLogin ? 'Login with iCloud' : 'Sign in with Apple'}
    </button>
  );
};

export default AppleAuth;