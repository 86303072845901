import { ALL_ARTIST_INSERT } from "./actionType";
const initialStore = [];

const AllArtist = (state = initialStore, action) => {
  switch (action.type) {
    case ALL_ARTIST_INSERT:
      return [...action.payload];
    default:
      return state;
  }
};

export default AllArtist;
