/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */
import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "../styles/main.scss";
import "../Layout/layout.scss";
import { ButtonGroup, Button, ButtonToolbar } from "react-bootstrap";
import Login from "../../Components/auth/Login";
import { ApiPost } from "../../helpers/API/ApiData";
import * as authUtil from "../../utils/auth.util";
import Minilogo from "../../Assets/Images/mini-logo.png";
import * as userUtil from "../../utils/user.util";
import Country from "../js/countries/Country";
import { Dropdown } from "react-bootstrap";
import LoadingBalls from "../../Assets/LoadingBalls";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Forgotpassword from "../js/Login/Forgotpassword";
import Userlogin from "../js/Login/Login";
import UserSignup from "../js/Login/Signup";
import useOnClickOutside from "../../helpers/outsideClick";
import avator from "@/Assets/Images/profile.png";

export default function Header() {
  const history = useNavigate();
  const logoutUser = () => {
    authUtil.logout();
    history("/");
    window.location.reload();
  };
  const user = userUtil.getUserInfo();
  const [searchTerm, setSearchTerm] = useState();
  const [musicData, setMusicData] = useState();
  const [artistData, setArtistData] = useState();
  const [productData, setProductData] = useState();
  const [genreData, setGenreData] = useState();
  const [Idc, setIdc] = useState();
  const [playlistData, setPlaylistData] = useState();
  const [loading, setLoading] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [forgotModal, setForgotModal] = useState(false);
  const [signupModal, setSignupModal] = useState(false);
  const [errors, setErrors] = useState([]);
  const [open, setOpen] = useState(false);
  const [signupErrors, setSignupErrors] = useState([]);
  const [formData, setFormData] = useState({});
  const [signupFormData, setSignupFormData] = useState({});
  const [countryInit, setCountryInit] = useState();
  const [passwordSeen, setPasswordSeen] = useState(false);
  const [selectionBox, setSelectionBox] = useState(true);
  const [formBox, setFormBox] = useState(false);
  const [forgetChange, setforgetChange] = useState();
  const [emailChange, setemailChange] = useState();
  const [emailvalid, setemailvalid] = useState();
  const [role, setRole] = useState("user");
  const handlePasswordSeen = () => {
    setPasswordSeen(!passwordSeen);
  };

  useEffect(() => {
    document.addEventListener("click", (e) => {
      setOpen(false);
    });
  }, []);

  // const profilePicture = useSelector((state) => state.ProfilePicture);

  const forgetSubmit = async () => {
    if (forgetChange === "" || forgetChange === undefined) {
      console.log(forgetChange, "forgotchnage");
      setemailChange("Please Enter the Email");
      setemailvalid("");
    } else if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        forgetChange
      )
    ) {
      setemailvalid("Please enter Valid Email");
      setemailChange("");
    } else {
      const data = {
        email: forgetChange,
      };

      await ApiPost(`users/forget-password?type=${role}`, data)
        .then((res) => {
          console.log(res.data);

          if (res.data.message === "PASSWORD RESET LINK SENT SUCCESSFULLY..!") {
            toast.success(res.data.message);
            setemailChange("");
            setemailvalid("");
            setForgotModal(false);
          } else {
            setemailChange("");
            setemailvalid("");
            toast.error(res.data.data);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("err", err);
          toast.error(err);
        });
    }
  };

  const signupValidate = () => {
    let error = [];
    let formIsValid = true;

    if (!signupFormData.email) {
      formIsValid = false;
      error["email"] = "Please Enter Email";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(signupFormData.email)
    ) {
      formIsValid = false;
      error["email"] = "Please Enter proper Email";
    }

    if (!signupFormData.firstName) {
      formIsValid = false;
      error["firstName"] = "Please Enter FirstName";
    } else if (!/^[aA-zZ\s]+$/.test(signupFormData.firstName)) {
      formIsValid = false;
      error["firstName"] = "only alphabetic character are allowd";
    }
    if (!signupFormData.lastName) {
      formIsValid = false;
      error["lastName"] = "Please Enter LastName";
    } else if (!/^[aA-zZ\s]+$/.test(signupFormData.lastName)) {
      formIsValid = false;
      error["firstName"] = "only alphabetic character are allowd";
    }
    if (!signupFormData.password) {
      formIsValid = false;
      error["password"] = "Please Enter Password";
    }

    setSignupErrors(error);
    return formIsValid;
  };
  const validate = () => {
    let error = [];
    let formIsValid = true;

    if (!formData.email) {
      formIsValid = false;
      error["email"] = "Please Enter Email";
    }
    if (!formData.password) {
      formIsValid = false;
      error["password"] = "Please Enter Password";
    }
    setErrors(error);
    return formIsValid;
  };

  const handleSignupSubmit = async (event) => {
    if ((event && event.keyCode === 13) || (event && event.type === "click")) {
      if (signupValidate()) {
        const data = {
          firstName: signupFormData.firstName,
          lastName: signupFormData.lastName,
          email: signupFormData.email,
          password: signupFormData.password,
          contact: signupFormData.contact,
          country: countryInit,
          countryCode: Idc,
        };
        setLoading(true);
        let err = [];

        await ApiPost("users/signup", data)
          .then((res) => {
            console.log(res);
            if (res?.data?.data === "Email has been already registered.") {
              err["error"] = res.data.data;
              setSignupErrors(err);
            } else {
              if (res.data.message === "Record Sucessfully Inserted.") {
                authUtil.setToken(res.data.data.token);
                userUtil.setUserInfo(res.data.data);
                setSignupModal(false);
                window.location.reload();
                setLoading(false);
              } else {
                setLoading(false);
                setErrors({ user: res.msg });
              }
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log("err", err);
          });
      }
    }
  };
  const handleSubmit = async (event) => {
    if ((event && event.keyCode === 13) || (event && event.type === "click")) {
      if (validate()) {
        const data = {
          email: formData.email,
          password: formData.password,
        };
        setLoading(true);
        await ApiPost("users/login", data)
          .then((res) => {
            if (res.data.message === "Successfully logged in.") {
              setLoading(false);
              authUtil.setToken(res.data.data.token);
              userUtil.setUserInfo(res.data.data);
              setLoginModal(false);
              window.location.reload();
            } else if (res.msg) {
              setLoading(false);
              setErrors({ user: "User does not exist." });
            } else {
              setLoading(false);
              setErrors({ user: "Please check username or password" });
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log("err", err);
          });
      }
    }
  };

  const handleSignupChange = (e) => {
    let { name, value } = e.target;

    setSignupFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleChange = (e) => {
    let { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCountry = (e) => {
    let { Name } = e.target;
  };

  const handleSearch = async (event) => {
    if (event.target.value.length > 1) {
      const data = {
        serchName: event.target.value,
      };
      await ApiPost("search/gsearch", data)
        .then((res) => {
          if (res?.data?.data?.musicRes) {
            setMusicData(res.data.data.musicRes);
            setOpen(true);
          }
          if (res?.data?.data?.ArtistRes) {
            setArtistData(res.data.data.ArtistRes);
            setOpen(true);
          }
          if (res?.data?.data?.playlistRes) {
            setPlaylistData(res.data.data.playlistRes);
            setOpen(true);
          }
          if (res?.data?.data?.genreRes) {
            setGenreData(res.data.data.genreRes);
            setOpen(true);
          }
          if (res?.data?.data?.ProductRes) {
            setProductData(res.data.data.ProductRes);
            setOpen(true);
          }
        })
        .catch((err) => {
          console.log("err");
          setOpen(false);
        });
    } else {
      setOpen(false);
      setMusicData([]);
      setArtistData([]);
      setPlaylistData([]);
      setGenreData([]);
      setProductData([]);
    }
  };

  const NavigatorRef2 = useRef();
  useOnClickOutside(NavigatorRef2, () => {
    setOpen(false);
  });

  return (
    <div>
      <header>
        <div className="container-fluid-one">
          <div className="flex items-center justify-between gap-alignment">
            <div className="flex items-center dropdownIcon">
              <div className="header-main-title flex items-center">
                <div className="header-main-logo">
                  <img src={Minilogo} />
                </div>
                <Link
                  to={{
                    pathname: "/",
                    data: "other-sidebar-open",
                  }}
                >
                  <h1 className="heading-font cursor-pointer font-medium tracking-normal mb-0">
                    Music
                  </h1>
                </Link>
              </div>
            </div>
            <div className="">
              <div className="relative flex w-full flex-wrap items-stretch search-input-width">
                <span className="z-10 h-full text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                  <i className="fas fa-search text-white"></i>
                </span>

                <div className="menu_dd_S relative" ref={NavigatorRef2}>
                  <div className="menu-img">
                    <input
                      type="text"
                      placeholder="Search"
                      className="px-3 py-3 header-search relative rounded-2xl w-full pl-10"
                      onChange={(event) => {
                        handleSearch(event);
                        event.target.value.length < 1 && setOpen(false);
                      }}
                    />
                  </div>
                  {open && (
                    <div className="menu-box_S absolute mt-3 w-full">
                      <div
                        className="bg-black flex flex-col w-100 max-w-full border box-shadow-cus rounded search-input-width"
                        style={{
                          borderColor: "#8f8f8f"
                        }}
                      >
                        {(musicData && musicData?.length === 0) ||
                        musicData?.length === "" ||
                        musicData?.length === undefined ||
                        musicData?.length === null ? (
                          <h1 className="text-white"></h1>
                        ) : (
                          musicData?.map((music) => {
                            return (
                              <div className="text-white p-2">
                                Music
                                <div className="text-white p-2 border-t">
                                  <div
                                    className="text-white cursor-pointer"
                                    onClick={() => {
                                      history(`/song/${music._id}`);
                                    }}
                                    OnTouchStart={() => {
                                      history(`/song/${music._id}`);
                                    }}
                                  >
                                    <Link to={`/song/${music._id}`}>
                                      {music.musicTitle}
                                    </Link>
                                  </div>
                                </div>
                                <Dropdown ref={NavigatorRef2}>
                             <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                              className="text-white search-input-width"
                               ></Dropdown.Toggle>
                    {open && (
                          <div ref={NavigatorRef2}>
                        <Dropdown.Menu></Dropdown.Menu>
                      </div>
                    )}
                              </Dropdown>
                              </div>
                            );
                          })
                        )}

                        {(artistData && artistData?.length === 0) ||
                        artistData?.length === "" ||
                        artistData?.length === undefined ||
                        artistData?.length === null ? (
                          <h1 className="text-white"> </h1>
                        ) : (
                          artistData?.map((artist) => {
                            return (
                              <div className="text-white p-2">
                                Artist
                                <div className="text-white p-2 border-t">
                                  <div
                                    className="text-white cursor-pointer"
                                    onClick={() => {
                                      history(`/profile/${artist._id}`);
                                    }}
                                    OnTouchStart={() => {
                                      history(`/profile/${artist._id}`);
                                    }}
                                  >
                                    <Link to={`/profile/${artist._id}`}>
                                      {artist.artistName}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        )}

                        {(productData && productData?.length === 0) ||
                        productData?.length === "" ||
                        productData?.length === undefined ||
                        productData?.length === null ? (
                          <h1 className="text-white"></h1>
                        ) : (
                          productData?.map((product) => {
                            return (
                              <div className="text-white p-2">
                                Merchandise
                                <div className="text-white p-2 border-t">
                                  <div
                                    className="text-white cursor-pointer"
                                    onClick={() => {
                                      history(`/cart/${product._id}`);
                                    }}
                                    OnTouchStart={() => {
                                      history(`/cart/${product._id}`);
                                    }}
                                  >
                                    <Link to={`/cart/${product._id}`}>
                                      {product.title}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        )}

                        {(genreData && genreData?.length === 0) ||
                        genreData?.length === "" ||
                        genreData?.length === undefined ||
                        genreData?.length === null ? (
                          <h1 className="text-white"> </h1>
                        ) : (
                          genreData?.map((genre) => {
                            return (
                              <div className="text-white p-2">
                                Genre
                                <div className="text-white p-2 border-t">
                                  <div
                                    className="text-white cursor-pointer"
                                    // onClick={() => {
                                    //   history(`/music/${genre._id}`);
                                    // }}
                                  >
                                    <Link to={`/music/${genre._id}`}>
                                      {genre.genreTitle}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        )}

                        {(playlistData && playlistData?.length === 0) ||
                        playlistData?.length === "" ||
                        playlistData?.length === undefined ||
                        playlistData?.length === null ? (
                          <h1 className="text-white"></h1>
                        ) : (
                          playlistData?.map((playlist) => {
                            return (
                              <div className="text-white p-2">
                                Playlist
                                <div className="text-white p-2 border-t">
                                  <div
                                    className="text-white cursor-pointer"
                                    // onClick={() => {
                                    //   history(`play/${playlist._id}`);
                                    // }}
                                  >
                                    <Link to={`play/${playlist._id}`}>
                                      {playlist.playlist_name}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                    </div>
                  )}
                  
                  {/* <Dropdown ref={NavigatorRef2}>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="text-white search-input-width"
                    ></Dropdown.Toggle>
                    {open && (
                      <div ref={NavigatorRef2}>
                        <Dropdown.Menu></Dropdown.Menu>
                      </div>
                    )}
                  </Dropdown> */}
                </div>
              </div>
            </div>
            <div className="mobile-header-flex-align">
              <div className="flex items-center justify-around whitespace-nowrap pr-5 padding-right-remove-header mobile-view-social-media-none">
                <ButtonToolbar aria-label="Toolbar with button groups">
                  <ButtonGroup aria-label="group">
                    <Button className="pr-3 pl-2 text-white">
                      <a
                        className="btn btn-primary"
                        href="https://www.facebook.com/7th-Century-Music-106256021766830"
                        target="self"
                        role="button"
                      >
                        <i className="fab fa-facebook-f" />
                      </a>
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>

                <ButtonToolbar aria-label="Toolbar with button groups">
                  <ButtonGroup aria-label="group">
                    <Button className="pr-3 pl-2 text-white">
                      <a
                        className="btn btn-primary"
                        href="https://www.instagram.com/7th.century.music/"
                        target="self"
                        role="button"
                      >
                        <i className="fab fa-instagram" />
                      </a>
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>

                <ButtonToolbar aria-label="Toolbar with button groups">
                  <ButtonGroup aria-label="group">
                    <Button className="pr-3 pl-2 text-white">
                      <a
                        className="btn btn-primary"
                        href="https://www.youtube.com/channel/UCZSMJE5tMI5QTyfWEOj1d2Q"
                        target="self"
                        role="button"
                      >
                        <i className="fab fa-youtube" />
                      </a>
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>

                <ButtonToolbar aria-label="Toolbar with button groups">
                  <ButtonGroup aria-label="group">
                    <Button className="pr-2 pl-2 text-white">
                      <a
                        className="btn btn-primary"
                        href="https://uk.linkedin.com/company/7th-century-music"
                        target="self"
                        role="button"
                      >
                        <i className="fab fa-linkedin" />
                      </a>
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </div>
              <div className="flex items-center">
                {authUtil.isLoggedIn() ? (
                  <>
                    <h1 className=" font-size-18  cursor-pointer heading-title-text-color font-medium tracking-normal mb-0">
                      Hello, {user?.user?.firstName || user?.user?.displayName}
                    </h1>
                    <div className="header-profile pl-3">
                      {/* <NavLink to="/profile">
                    
                  </NavLink> */}
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="text-white"
                        >
                          <div className="flex items-center">
                            <img
                              className="rounded-full"
                              // src={
                              //   profilePicture && profilePicture.profile
                              //     ? profilePicture.profile
                              //     : user && user.user && user.user.profile
                              // }
                              src={
                                user && user.user && user.user.profile
                                  ? user.user.profile
                                  : avator
                              } alt=""
                            />
                            <i className="fas fa-sort-down white-text-color font-size-20 pl-2"></i>
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="z-10">
                          <div
                            className="flex flex-col w-32 max-w-full rounded mt-2 box-shadow-cus border-color"
                            style={{ backgroundColor: "#090C10" }}
                          >
                            <div
                              className="text-white p-2"
                              onClick={() => {
                                history("/userprofile");
                              }}
                              OnTouchStart={() => {
                                history("/userprofile");
                              }}
                            >
                              Profile
                            </div>
                            <div
                              className="text-white p-2"
                              onClick={() => {
                                history("/mymusics");
                              }}
                              OnTouchStart={() => {
                                history("/mymusics");
                              }}
                            >
                              My Music
                            </div>
                            <Link to="/" onClick={() => logoutUser()}>
                              <div className="text-white p-2">Logout</div>
                            </Link>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <h1
                      className="heading-font font-size-17 pr-8 cursor-pointer heading-title-text-color font-medium tracking-normal mb-0"
                      onClick={() => setLoginModal(true)}
                      OnTouchStart={() => setLoginModal(true)}
                    >
                      Log in
                    </h1>
                    <h1
                      className="heading-font font-size-17 cursor-pointer heading-title-text-color font-medium tracking-normal mb-0"
                      onClick={() => setSignupModal(true)}
                      OnTouchStart={() => setSignupModal(true)}
                    >
                      Sign up
                    </h1>
                  </>
                )}
              </div>
            </div>
          </div>

          {forgotModal ? (
            <Forgotpassword
              setSignupModal={setSignupModal}
              setLoginModal={setLoginModal}
              setForgotModal={setForgotModal}
            />
          ) : null}

          {loginModal ? (
            <Userlogin
              setSignupModal={setSignupModal}
              setLoginModal={setLoginModal}
              setForgotModal={setForgotModal}
            />
          ) : null}

          {signupModal ? (
            <UserSignup
              setSignupModal={setSignupModal}
              setLoginModal={setLoginModal}
              setForgotModal={setForgotModal}
            />
          ) : null}
          {loading && <LoadingBalls />}
        </div>
      </header>
    </div>
  );
}
